import { SHOW, IMAGE, BACKGROUND, URL } from '../../constants/banner';

export const Banner = () => {
  return SHOW ? (
    <a href={URL} className="block" style={{ backgroundColor: BACKGROUND }}>
      <span className="block max-w-7xl w-80 mx-auto p-2 md:py-4 md:px-0 ">
        <img src={IMAGE} />
      </span>
    </a>
  ) : null;
};
