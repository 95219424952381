import { ReactNode } from 'react';
import classnames from 'classnames';
import { KeyValue } from '../../lib/keyboard';
import { CharStatus } from '../../lib/statuses';

type Props = {
  children?: ReactNode;
  value: KeyValue;
  width?: number;
  status?: CharStatus;
  onClick: (value: KeyValue) => void;
};

export const Key = ({
  children,
  status,
  width = 40,
  value,
  onClick,
}: Props) => {
  const classes = classnames(
    'flex items-center justify-center rounded mx-0.5 text-xs font-bold cursor-pointer select-none dark:text-white',
    {
      'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400':
        !status,
      'bg-slate-400 text-white': status === 'absent',
      'bg-teal-500 hover:bg-teal-600 active:bg-teal-700 text-white':
        status === 'correct',
      'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white':
        status === 'present',
    }
  );

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value);
    event.currentTarget.blur();
  };

  return (
    <button
      style={{ width: `${width}px`, height: '58px' }}
      className={classes}
      onClick={handleClick}
    >
      {children || value}
    </button>
  );
};
