export const WORDS = [
  'LATKES', // 2 February 2022
  'TANACH',
  'YIZKOR',
  'KOSHER',
  'FARHUD',
  'SCROLL',
  'SHOFAR',
  'SHALOM',
  'MIKVEH',
  'MENSCH',
  'AMIDAH',
  'KIPPUR',
  'FLEISH',
  'GIMMEL',
  'KNEIDL',
  'KIPPAH',
  'ALIYAH',
  'BUBKES',
  'TUCHES',
  'BRACHA',
  'TEMPLE',
  'GEMARA',
  'SHMUTZ',
  'LCHAIM',
  'CHAZAN',
  'HEBREW',
  'SHTICK',
  'PRAYER', // 1 March 2022
  'BABKAH',
  'BISSEL',
  'SANDEK',
  'POGROM',
  'MAARIV',
  'SCHLEP',
  'SIMCHA',
  'SHIVAH',
  'KARPAS',
  'ETROGS',
  'HASHEM',
  'MEZUZA',
  'MISHNA',
  'CHABAD',
  'CHALLA',
  'AUFRUF',
  'KISLEV',
  'TALLIT',
  'LULAVS',
  'HUMMUS',
  'MINYAN',
  'TZADIK',
  'KINDER',
  'SIDDUR',
  'SCHLUB',
  'KODESH',
  'TALMUD',
  'RABBIS',
  'MINCHA',
  'NISSAN',
  'PESACH', // 1 April 2022
  'SUKKAH',
  'TZURIS',
  'CHATAN',
  'ISRAEL',
  'TAMMUZ',
  'JEWISH',
  'YALLAH',
  'SUKKOT',
  'KVETCH',
  'HAGBAH',
  'KALLAH',
  'MATZAH',
  'LADINO',
  'ELIJAH',
  'SHEVAT',
  'CANDLE',
  'PASSUL',
  'HALAVI',
  'SHTARK',
  'CHEREM',
  'MARROR',
  'SHANDA',
  'YONTIF',
  'ARAVAH',
  'GROGER',
  'TICHEL',
  'RIKKUD',
  'SAVTAH',
  'DAYYAN',
  'SIYYUM', // 1 May 2022
  'HAKAFA',
  'SICHAH',
  'NIDDAH',
  'SHIKUR',
  'SHLOCK',
  'BASARI',
  'CHIYUV',
  'SHERUT',
  'CHOLEH',
  'SHOHET',
  'SHECHT',
  'SCHTUP',
  'AVELUT',
  'AMALEK',
  'KADIMA',
  'MENORA',
  'LIMMUD',
  'MABRUK',
  'CHAZAL',
  'EMUNAH',
  'TZAHAL',
  'SCHNOR',
  'KITTEL',
  'NEBISH',
  'LEVAYA',
  'SHKIAH',
  'BEDIKA',
  'MOADON',
  'DRASHA',
  'MESORA',
  'KESHER', // 1 June 2022
  'MAMZER',
  'ADONAI',
  'BIRKON',
  'TZEDEK',
  'PLATTA',
  'TZIBUR',
  'SHVITZ',
  'SHEMOT',
  'FARFEL',
  'SEGULA',
  'SUGIAH',
  'MIDDOT',
  'DVARIM',
  'SHLUMP',
  'HAVURA',
  'HILONI',
  'NUDNIK',
  'SLICHA',
  'JEWFRO',
  'SMICHA',
  'SHPIEL',
  'KVUTZA',
  'HAGADA',
  'SEDRAH',
  'MATZAV',
  'CHASID',
  'REFORM',
  'BOCHUR',
  'SHAILA',
  'DUCHEN', // 1 July 2022
  'SHMATA',
  'DERECH',
  'YIGDAL',
  'GENIZA',
  'TIKKUN',
  'MANNAH',
  'HEFKER',
  'SHTUSS',
  'MAMASH',
  'ZEROAH',
  'HACHAM',
  'KAVANA',
  'MERKAZ',
  'NOODGE',
  'DAYENU',
  'AVODAH',
  'MINHAG',
  'PARSHA',
  'KADISH',
  'PASSUK',
  'HUTZPA',
  'SFORIM',
  'NEGIAH',
  'NIDCHE',
  'SHMUCK',
  'KIBITZ',
  'SHLITA',
  'TEFILA',
  'TAHINI',
  'MUSSAF',
  'CHUMRA', // 1 August 2022
  'ALEINU',
  'NACHAS',
  'PUPPIK',
  'NACHON',
  'CHEDER',
  'SABABA',
  'TZNIUS',
  'KOHEIN',
  'BORSHT',
  'PUSHKA',
  'GIYYUR',
  'NIFTAR',
  'EICHAH',
  'SHEKEL',
  'MITZVA',
  'CHAMSA',
  'PESHAT',
  'PAREVE',
  'BEITZA',
  'KTUVIM',
  'ACHDUT',
  'MAIDEL',
  'ZAATAR',
  'BENTSH',
  'HESPED',
  'KUMKUM',
  'HAMISH',
  'DOOGMA',
  'CHIZUK',
  'CHIDON',
  'HAREDI', // 1 September 2022
  'SCHNOZ',
  'MAKPID',
  'GOYISH',
  'SCHACH',
  'TSHUVA',
  'CHAVAL',
  'SHIKSA',
  'KASHER',
  'TSURIS',
  'CHUPAH',
  'CHRAIN',
  'CHAGIM',
  'PASKEN',
  'MAHANE',
  'PECKEL',
  'CHEVRA',
  'CHESED',
  'GARTEL',
  'BUBBIE',
  'MUKTZA',
  'CHAZIR',
  'SHMIRA',
  'MUTTAR',
  'TIYYUL',
  'JEWDAR',
  'GEVALT',
  'KEPPIE',
  'KOLLEL',
  'MASHAL',
  'AKEDAH',
  'AGUNAH', // 1 October 2022
  'TATTIE',
  'AVERAH',
  'KISHKE',
  'YOTZEI',
  'KETUBA',
  'BUBELE',
  'BEHEMA',
  'SHVACH',
  'HILLEL',
  'NEBACH',
  'KABALA',
  'NIGGUN',
  'GABBAI',
  'GELILA',
  'PERUSH',
  'DYBBUK',
  'GEZUNT',
  'SHMURA',
  'BLINTZ',
  'CHAVER',
  'BSAMIM',
  'SHTETL',
  'ARAVOT',
  'HINUCH',
  'HANUKA',
  'YICHUS',
  'SHNAPS',
  'SHIRAH',
  'TZIMES',
  'SECHEL',
  'CHARIF', // 1 November 2022
  'ZAFTIG',
  'BOOBAH',
  'MACHER',
  'HAMETZ',
  'ELOHIM',
  'MIMUNA',
  'KAPARA',
  'SHMEAR',
  'BUPKIS',
  'KVATER',
  'TAHARA',
  'ZIVVUG',
  'LASHON',
  'KRIYAH', // 15 November 2022
  // repeated here after the fact because it looped, think i counted wrong above somewhere also^
  'LATKES',
  'TANACH',
  'YIZKOR',
  'KOSHER',
  'FARHUD',
  'SCROLL',
  'SHOFAR',
  'SHALOM',
  'MIKVEH',
  'MENSCH',
  'AMIDAH',
  'KIPPUR', // 28 November 2022
  'FLEISH',
  'GIMMEL',
  'KNEIDL', // 1 December 2022
  'KIPPAH',
  'ALIYAH',
  'BUBKES',
  'TUCHES',
  'BRACHA',
  'TEMPLE',
  'GEMARA',
  'SHMUTZ',
  'LCHAIM',
  'CHAZAN',
  'HEBREW',
  'SHTICK',
  'PRAYER',
  'BABKAH',
  'BISSEL',
  'SANDEK',
  'POGROM',
  'MAARIV',
  'SCHLEP',
  'SIMCHA',
  'SHIVAH',
  'KARPAS',
  'ETROGS',
  'HASHEM',
  'MEZUZA',
  'MISHNA',
  'CHABAD',
  'CHALLA',
  'AUFRUF',
  'KISLEV',
  'TALLIT', // 1 January 2023
  'LULAVS',
  'HUMMUS',
  'MINYAN',
  'TZADIK',
  'KINDER',
  'SIDDUR',
  'SCHLUB',
  'KODESH',
  'TALMUD',
  'RABBIS',
  'MINCHA',
  'NISSAN',
  'PESACH',
  'SUKKAH',
  'TZURIS',
  'CHATAN',
  'ISRAEL',
  'TAMMUZ',
  'JEWISH',
  'YALLAH',
  'SUKKOT',
  'KVETCH',
  'HAGBAH',
  'KALLAH',
  'MATZAH',
  'LADINO',
  'ELIJAH',
  'SHEVAT',
  'CANDLE',
  'PASSUL',
  'HALAVI', // 1 February 2023
  'SHTARK',
  'CHEREM',
  'MARROR',
  'SHANDA',
  'YONTIF',
  'ARAVAH',
  'GROGER',
  'TICHEL',
  'RIKKUD',
  'SAVTAH',
  'DAYYAN',
  'SIYYUM',
  'HAKAFA',
  'SICHAH',
  'NIDDAH',
  'SHIKUR',
  'SHLOCK',
  'BASARI',
  'CHIYUV',
  'SHERUT',
  'CHOLEH',
  'SHOHET',
  'SHECHT',
  'SCHTUP',
  'AVELUT',
  'AMALEK',
  'KADIMA',
  'MENORA', // 1 March 2023
  'LIMMUD',
  'MABRUK',
  'CHAZAL',
  'EMUNAH',
  'TZAHAL',
  'SCHNOR',
  'KITTEL',
  'NEBISH',
  'LEVAYA',
  'SHKIAH',
  'BEDIKA',
  'MOADON',
  'DRASHA',
  'MESORA',
  'KESHER',
  'MAMZER',
  'ADONAI',
  'BIRKON',
  'TZEDEK',
  'PLATTA',
  'TZIBUR',
  'SHVITZ',
  'SHEMOT',
  'FARFEL',
  'SEGULA',
  'SUGIAH',
  'MIDDOT',
  'DVARIM',
  'SHLUMP',
  'HAVURA',
  'HILONI', // 1 April 2023
  'NUDNIK',
  'SLICHA',
  'JEWFRO',
  'SMICHA',
  'SHPIEL',
  'KVUTZA',
  'HAGADA',
  'SEDRAH',
  'MATZAV',
  'CHASID',
  'REFORM',
  'BOCHUR',
  'SHAILA',
  'DUCHEN',
  'SHMATA',
  'DERECH',
  'YIGDAL',
  'GENIZA',
  'TIKKUN',
  'MANNAH',
  'HEFKER',
  'SHTUSS',
  'MAMASH',
  'ZEROAH',
  'HACHAM',
  'KAVANA',
  'MERKAZ',
  'NOODGE',
  'DAYENU',
  'AVODAH', // 1 May 2023
  'MINHAG',
  'PARSHA',
  'KADISH',
  'PASSUK',
  'HUTZPA',
  'SFORIM',
  'NEGIAH',
  'NIDCHE',
  'SHMUCK',
  'KIBITZ',
  'SHLITA',
  'TEFILA',
  'TAHINI',
  'MUSSAF',
  'CHUMRA',
  'ALEINU',
  'NACHAS',
  'PUPPIK',
  'NACHON',
  'CHEDER',
  'SABABA',
  'TZNIUS',
  'KOHEIN',
  'BORSHT',
  'PUSHKA',
  'GIYYUR',
  'NIFTAR',
  'EICHAH',
  'SHEKEL',
  'MITZVA',
  'CHAMSA', // 1 June 2023
  'PESHAT',
  'PAREVE',
  'BEITZA',
  'KTUVIM',
  'ACHDUT',
  'MAIDEL',
  // Added 5+7 letter words for 8 June, regenerated word list for all words starting here
  'MOSERIM', // 8 June 2023
  'DERECH',
  'SAFEK',
  'MAHARAT',
  'NIGGUN',
  'VAYIKRA',
  'ZIVUG',
  'SABABA',
  'HAFGANA',
  'SHTIM',
  'DRASH',
  'SHALOM',
  'PAYOS',
  'PATUR',
  'CHIYUV',
  'ETROG',
  'KVELL',
  'ARAVA',
  'HESPED',
  'GELILA',
  'GEZERAH',
  'AVELUT',
  'MAMASH',
  'GEZUNT', // 1 July 2023
  'YALLAH',
  'NISAN',
  'SCROLL',
  'RABBIS',
  'ELOHIM',
  'MIKVEH',
  'CHARIF',
  'MECHAYA',
  'GANTSEH',
  'MACHER',
  'LATKES',
  'HEIMISH',
  'TZNIUS',
  'LABRIUT',
  'TZETL',
  'SHANDA',
  'KIMAT',
  'LIGHT',
  'SHIRA',
  'SHEFELE',
  'RIKKUD',
  'MADRICH',
  'GOLUS',
  'GARTEL',
  'ARAMAIC',
  'SCHNOZ',
  'MUTAR',
  'SIKKUM',
  'TIYUL',
  'MAARIV',
  'ELIJAH', // 1 August 2023
  'MOSHL',
  'MELACHA',
  'RIKUD',
  'YELADIM',
  'TATTIE',
  'PERUSH',
  'DAYAN',
  'CHOLEH',
  'KHREMZL',
  'BADCHEN',
  'ISRAEL',
  'TIYYUL',
  'CHAMETZ',
  'AGGADAH',
  'BEITZAH',
  'GEMARA',
  'ZIONISM',
  'AMALEK',
  'NUDNIK',
  'PASUK',
  'SIDUR',
  'SHATNEZ',
  'KETUVIM',
  'ATZILUS',
  'KULAH',
  'TANACH',
  'MACHANE',
  'ASIFA',
  'CHAGIM',
  'SHTICK',
  'MINHAG', // 1 September 2023
  'MAKPID',
  'NEBACH',
  'YESOD',
  'TZITZIT',
  'AGUNAH',
  'MUKTZA',
  'SCHMUTZ',
  'ZAFTIG',
  'SCHUG',
  'ASKAN',
  'BLECH',
  'SHENI',
  'AMCHA',
  'TZADIK',
  'PETEK',
  'TZEDEK',
  'YICHUS',
  'MENSCH',
  'SHVER',
  'TSHUVA',
  'CHAKIRA',
  'PUPIK',
  'SHOFAR',
  'SCHNORR',
  'KAVANA',
  'KVETCH',
  'BUBBALE',
  'FRESSER',
  'KOVED',
  'LULAV', // 1 October 2023
  'BUBELE',
  'SIMCHA',
  'BAYIT',
  'KEZAYIT',
  'GADOL',
  'MEGILAH',
  'SHLEP',
  'KIKAR',
  'TENAI',
  'NEGIAH',
  'MIDDOT',
  'MISHNA',
  'SIVUV',
  'MASHAL',
  'PARNASA',
  'BUSHA',
  'SHMALTZ',
  'HAKDAMA',
  'POLKEYS',
  'JAPPY',
  'CHUPAH',
  'DAVKA',
  'KISHKAS',
  'KIBITZ',
  'BLINTZ',
  'TUCHES',
  'KEPPELE',
  'HALAVI',
  'MENUCHA',
  'REFORM',
  'YOSEM', // 1 November 2023
  'BADEKEN',
  'CHAZAN',
  'EMUNAH',
  'CHUMRA',
  'SIDDUR',
  'BODEK',
  'MASHPIA',
  'HANUKA',
  'LECHAIM',
  'GIYYUR',
  'BIMAH',
  'TIKKUN',
  'SHOMER',
  'PLATTA',
  'GABAI',
  'TZIMMES',
  'SUKKAH',
  'AVODAH',
  'GROGGER',
  'PASSUK',
  'GENIZAH',
  'ICHSA',
  'DYBBUK',
  'BAGEL',
  'REMEZ',
  'PUSHKA',
  'AVEILUT',
  'KNISH',
  'MAHANE',
  'PESHAT', // 1 December 2023
  'LIMMUD',
  'HAVURAH',
  'LITVISH',
  'BASHERT',
  'BOCHUR',
  'KELEV',
  'TZURIS',
  'SHAILA',
  'PRITSUS',
  'BALABOS',
  'SIYYUM',
  'DAYYAN',
  'CHACHAM',
  'BUJEW',
  'CHABURA',
  'CHUSH',
  'SHLOCK',
  'BITUL',
  'SHLUMP',
  'VANTZ',
  'KASHER',
  'HILONI',
  'SHAVUOT',
  'SMICHA',
  'KEDAI',
  'SHABBAT',
  'ZIVVUG',
  'MABUL',
  'NISAYON',
  'AKEDAH',
  'SUKKOT', // 1 January 2024
  'AVADE',
  'HAGADA',
  'SHOAH',
  'SHISSEL',
  'NEDIVUT',
  'SHEKET',
  'YEKKE',
  'FARIBEL',
  'SHELITA',
  'YENTA',
  'SHAMASH',
  'ACHLA',
  'MEGUYAR',
  'TOREN',
  'MAVEN',
  'ZEROA',
  'EMESDIK',
  'MITBACH',
  'KEPPY',
  'ZATAR',
  'TORANUT',
  'BABKA',
  'NACHON',
  'HACHAM',
  'KAPARA',
  'KRIYAH',
  'BORSCHT',
  'HAVURA',
  'MITZVAH',
  'BENCHER',
  'MAROR', // 1 February 2024
  'NACHAS',
  'KASHE',
  'SHVITZ',
  'MEZUZA',
  'HAFSAKA',
  'ADOSHEM',
  'JEWISH',
  'TAHINI',
  'SHMUK',
  'MOADON',
  'YIZKOR',
  'SEFER',
  'GIMMEL',
  'LOKSHEN',
  'SMICHAH',
  'TALIT',
  'DRASHA',
  'CHEDER',
  'SHVACH',
  'KIPPAH',
  'KEPPIE',
  'MANNAH',
  'TAHARA',
  'HAGBAH',
  'GESHMAK',
  'TALLIT',
  'MITZVA',
  'FLEISH',
  'SPIEL', // 1 March 2024
  'ADONAI',
  'KASHRUT',
  'POGROM',
  'KITNIOT',
  'NATLA',
  'NISSAN',
  'GELILAH',
  'TEMPLE',
  'PAREVE',
  'KLEZMER',
  'KADISH',
  'GOYISH',
  'CHRAIN',
  'BEHEMA',
  'MACHZOR',
  'BUBBIE',
  'SHMECKY',
  'ADAFINA',
  'CHOFESH',
  'CHOLENT',
  'HAKAFA',
  'TEIKU',
  'KETUBAH',
  'KNEIDL',
  'CHAMSA',
  'SICHAH',
  'PEKEL',
  'MENORAH',
  'TZRIF',
  'SHEITEL',
  'GEDOLIM', // 1 April 2024
  'SABRA',
  'SEDER',
  'KUGEL',
  'SCHLONG',
  'SCHMO',
  'BENTSH',
  'MISHNAH',
  'ZEROAH',
  'CHAVAL',
  'MAMZER',
  'CHAZAL',
  'CHASID',
  'DEVARIM',
  'LADINO',
  'BUREKAS',
  'LASHON',
  'SIDRA',
  'SVIVA',
  'PLONI',
  'SHTARK',
  'SCHMEAR',
  'DUGMA',
  'MIDRASH',
  'PARVE',
  'SHAYACH',
  'HAGBA',
  'BROIGES',
  'KITTEL',
  'NIKAYON',
  'HEICHAL',
  'MEKAYEM', // 1 May 2024
  'SHEKEL',
  'KOTEL',
  'KUMZITS',
  'SCHLUB',
  'MUSAR',
  'POSEK',
  'KLUTZ',
  'CHIDUSH',
  'MEATY',
  'FRESS',
  'KEDUSHA',
  'GESHEFT',
  'BUBKES',
  'KAVANAH',
  'BISSEL',
  'SHTUP',
  'CHATUNA',
  'OIRIK',
  'TAIVA',
  'HEFKER',
  'JDATE',
  'HUTZPA',
  'KIDDUSH',
  'MADREGA',
  'SEDRAH',
  'SHIRAH',
  'SHMUCK',
  'STRUDEL',
  'SHMIRA',
  'SUGYA',
  'CHEVRA', // 1 June 2024
  'ASSUR',
  'HAREDI',
  'MIMUNA',
  'HASID',
  'MEZAKEH',
  'KOLLEL',
  'SHLITA',
  'TSORRIS',
  'GAYVE',
  'CHATAN',
  'SHTETL',
  'GEHENOM',
  'BRACHA',
  'CANDLE',
  'PARSHA',
  'ARAVAH',
  'CHILONI',
  'CHEREM',
  'KUNTZ',
  'DVARIM',
  'YESHIVA',
  'KVATER',
  'KAHAL',
  'ZIONIST',
  'JEWDAR',
  'SHOHET',
  'FARFEL',
  'FALAFEL',
  'MUTTAR',
  'SHMURA', // 1 July 2024
  'KITAH',
  'KTUVIM',
  'CHAVER',
  'HALACHA',
  'GONIF',
  'BSAMIM',
  'PEULA',
  'SHLUFFY',
  'BUBBE',
  'SHNOZ',
  'SHNAPS',
  'GOLEM',
  'SHTAR',
  'MABROUK',
  'SCHNOR',
  'SEFORIM',
  'ADERABA',
  'KINUS',
  'PESADIK',
  'KADDISH',
  'ACHDUT',
  'SAVTAH',
  'MALSHIN',
  'CHESED',
  'DOIKAYT',
  'ALIYAH',
  'CHATICH',
  'INYAN',
  'YIDDISH',
  'SFORIM',
  'MEKAREV', // 1 August 2024
  'MERKAZ',
  'TREIF',
  'PECKEL',
  'BEDIKA',
  'CHUMASH',
  'CHALLAH',
  'KUMKUM',
  'CHIDON',
  'SCHACH',
  'PASSUL',
  'GIYUR',
  'TAHOR',
  'HAKAFAH',
  'HAMISH',
  'SUGIAH',
  'MATZAH',
  'BICHLAL',
  'KESHER',
  'TAMMUZ',
  'SHMEAR',
  'HEBREW',
  'CHUPPAH',
  'PUNIM',
  'HALEVAI',
  'AMORA',
  'BEITZA',
  'SCHAV',
  'YONTIF',
  'EICHAH',
  'HASIDIC',
  'MUSSAF', // 1 September 2024
  'REBBE',
  'MECHULA',
  'KOSHER',
  'MIGRASH',
  'MOHEL',
  'PRAYER',
  'DOOGMA',
  'KASHA',
  'SANDEK',
  'ETROGS',
  'ARVIT',
  'MATIR',
  'GOYTE',
  'TZAHAL',
  'PEREK',
  'KINDER',
  'BIALY',
  'TEATRON',
  'BORSHT',
  'AUFRUF',
  'MESORA',
  'MESORAH',
  'SHEMA',
  'PESACH',
  'SHTUSS',
  'MATZAV',
  'GABBAI',
  'CHALLA',
  'HAZARAI',
  'SHEVAT', // 1 October 2024
  'MENORA',
  'NIDCHE',
  'AMIDAH',
  'MATZO',
  'SHPRITZ',
  'SAKANAH',
  'HETER',
  'SOFER',
  'PATSHKE',
  'KADIMA',
  'AVERAH',
  'SIVAN',
  'TZIBUR',
  'MINCHA',
  'ALEINU',
  'PASKEN',
  'MEZUZAH',
  'SHUCKLE',
  'LEVAYA',
  'KITKE',
  'DRECK',
  'DVEYKUS',
  'MABRUK',
  'BIRKON',
  'SHEMOT',
  'GLATT',
  'SHIKSA',
  'SLICHA',
  'BOOBAH',
  'NIFTAR', // 1 November 2024
  'KIBBUTZ',
  'SHERUT',
  'KOHEIN',
  'BABKAH',
  'BERYA',
  'KAPAROT',
  'MAZEL',
  'HIZUK',
  'BEKISHE',
  'MEKOR',
  'TZEIT',
  'HASHEM',
  'MAIDEL',
  'TAMEI',
  'LATKE',
  'SHOCHET',
  'MEVATER',
  'PURIM',
  'SAVTA',
  'TZIMES',
  'MILKY',
  'GROGER',
  'MAZIK',
  'SHPIEL',
  'HINUCH',
  'KNESSET',
  'KETER',
  'TICHEL',
  'PUPPIK',
  'CHUMRAH', // 1 December 2024
  'TEFILA',
  'KIBBITZ',
  'SHMUTZ',
  'PNIMI',
  'SCHTUP',
  'MACHMIR',
  'LCHAIM',
  'SHIVAH',
  'MAMALEH',
  'PLATA',
  'NESHAMA',
  'TEHILIM',
  'KABALA',
  'SHTUS',
  'HAMETZ',
  'CHALAVI',
  'BESAMIM',
  'NIGUN',
  'DAYENU',
  'KALLAH',
  'ACHSHAV',
  'TEKES',
  'NEBISH',
  'SHTUM',
  'KRIAH',
  'BASARI',
  'ZAATAR',
  'DUCHEN',
  'DREIDEL',
  'LAINING',
  'MILCHIG', // 1 January 2025
  'NOODGE',
  'MISHKAN',
  'CHINUCH',
  'TISCH',
  'TORAH',
  'TOYVE',
  'NEDER',
  'HILLEL',
  'TEFILAH',
  'SHICKER',
  'SHIKUR',
  'MELDADO',
  'KISLEV',
  'TSURIS',
  'GLIDA',
  'BANOT',
  'MINYAN',
  'GEVALT',
  'KETUBA',
  'ARBES',
  'HUMMUS',
  'YIGDAL',
  'JEWFRO',
  'ARAVOT',
  'LULAVS',
  'SHMATA',
  'HAGADAH',
  'KIRUV',
  'SECHEL',
  'TEVET',
  'SHECHT', // 1 February 2025
  'CHIZUK',
  'PLOTZ',
  'KARPAS',
  'ONESH',
  'KODESH',
  'GENIZA',
  'HILLULA',
  'SHIUR',
  'YOTZEI',
  'RENEWAL',
  'PATSH',
  'DAVEN',
  'MUSAF',
  'MESHUGA',
  'SHLISHI',
  'AKITZUR',
  'KISHKE',
  'SICHA',
  'MARROR',
  'SCHLEP',
  'NEROT',
  'TEFILIN',
  'SCHVITZ',
  'FRIER',
  'MUKTZAH',
  'NIDDAH',
  'PARASHA',
  'TACHLIS', // 1 March 2025
  'SHKIAH',
  'RABBA',
  'TANYA',
  'GENUG',
  'SVARA',
  'BAITSIM',
  'COHEN',
  'NEBBISH',
  'RUACH',
  'HAMSA',
  'ADMOR',
  'BALAGAN',
  'LEARN',
  'MILAH',
  'GMACH',
  'LOSHN',
  'CHAZIR',
  'SHEYMES',
  'SIYUM',
  'DEGEL',
  'KIPPUR',
  'TALMUD',
  'ALMEMAR',
  'PROST',
  'KNAIDEL',
  'KVUTZA',
  'HAZERET',
  'FARHUD',
  'BESEDER',
  'SEGULA',
  'BUPKIS', // 1 April 2025
  'SHVANTZ',
  'CHASHUV',
  'CHABAD',
] as const;

type Solution = typeof WORDS[number];

export const DEFINITIONS: Record<
  Solution,
  { url: string; definition: string }
> = {
  ACHDUT: {
    url: 'https://jel.jewish-languages.org/words/2219',
    definition: `Unity, solidarity, harmony. From the Hebrew root "Yachad" - together.`,
  },
  ADONAI: {
    url: 'https://jel.jewish-languages.org/words/5',
    definition: `The Big Guy in the Sky. Literally, "My Lord."`,
  },
  AGUNAH: {
    url: 'https://jel.jewish-languages.org/words/7',
    definition: `Literally, a chained woman whose husband will not give her a get, or Jewish divorce. Many advocacy groups are working on mainstream Halachic solutions but it remains very problematic.`,
  },
  AKEDAH: {
    url: 'https://jel.jewish-languages.org/words/1348',
    definition: `The binding of Isaac, the famous Biblical story told in the Book of Genesis.`,
  },
  ALEINU: {
    url: 'https://jel.jewish-languages.org/words/2065',
    definition: `A core prayer in Jewish liturgy. We bend our knees at the first line.`,
  },
  ALIYAH: {
    url: 'https://jel.jewish-languages.org/words/13',
    definition: `Literally in Hebrew, 'to go up.' It has two meanings: 1. To emigrate to Israel. 2. To be called to the Torah. Either way, yallah!`,
  },
  AMALEK: {
    url: 'https://jel.jewish-languages.org/words/1949',
    definition: `Enemy of the Jews. Originally referred to a nomadic Biblical people - now it's just any old nemesis.`,
  },
  AMIDAH: {
    url: 'https://jel.jewish-languages.org/words/20',
    definition: `On your feet. The 'Standing Prayer' is the core liturgy of the three daily prayer services.`,
  },
  ARAVAH: {
    url: 'https://jel.jewish-languages.org/words/2068',
    definition: `The Aravah is the lowland area of Israel's eastern Negev Desert. Very hot, but it's a dry heat.`,
  },
  ARAVOT: {
    url: 'https://jel.jewish-languages.org/words/2067',
    definition: `You've heard of the lulav and etrog - but aravot are also a Sukkot staple. They're willow branches, one of the Four Species.`,
  },
  AUFRUF: {
    url: 'https://jel.jewish-languages.org/words/26',
    definition: `Mazal Tov! Grooms are called to the Torah the Shabbat before their weddings in the celebration known as an aufruf.`,
  },
  AVELUT: {
    url: 'https://jel.jewish-languages.org/words/2076',
    definition: `Mourning. When you're in avelut you do things like sit shiva, say Kaddish, and avoid parties.`,
  },
  AVERAH: {
    url: 'https://jel.jewish-languages.org/words/28',
    definition: `A sin, transgression. "Oy, I have so many averahs to atone for this Yom Kippur."`,
  },
  AVODAH: {
    url: 'https://jel.jewish-languages.org/words/1863',
    definition: `Literally, work or service. A popular name for chore duty at Jewish summer camp.`,
  },
  BABKAH: {
    url: '',
    definition: `Yummmm. Gooey, chocolatey or cinnamony yeast cakey goodness!`,
  },
  BASARI: {
    url: 'https://jel.jewish-languages.org/words/2205',
    definition: `Meaty! Derived from Hebrew "basar", meat. "Don't use that basari knife on the cheesecake."`,
  },
  BEDIKA: {
    url: 'https://jel.jewish-languages.org/words/2040',
    definition: `A search or check. Before Passover, many people do a "bedikat chametz", or search for chametz, in their homes.`,
  },
  BEHEMA: {
    url: 'https://jel.jewish-languages.org/words/48',
    definition: `Massive Biblical animal (think "behemoth") or boorish, uncouth person.`,
  },
  BEITZA: {
    url: 'https://jel.jewish-languages.org/words/2347',
    definition: `An egg. Also a Talmudic measurement equalling the volume of, you guessed it, an egg.`,
  },
  BENTSH: {
    url: 'https://jel.jewish-languages.org/words/58',
    definition: `To recite Grace After Meals (Birkat HaMazon). "Let's have dessert before we bentsh."`,
  },
  BIRKON: {
    url: 'https://jel.jewish-languages.org/words/2398',
    definition: `Prayer booklet or card with the text of the Birkat HaMazon, Blessing After Meals. Pretty monogrammed copies are popular wedding favours.`,
  },
  BISSEL: {
    url: '',
    definition: `Not too hungry? Just ess a bissel! (Eat a bite in Yiddish.)`,
  },
  BLINTZ: {
    url: 'https://jel.jewish-languages.org/words/1973',
    definition: `Yummy thin pancake filled with cheese or jam or potatoes. Kind of like a Jewish crepe.`,
  },
  BOCHUR: {
    url: 'https://jel.jewish-languages.org/words/1370',
    definition: `A young Jewish man, usually a Yeshiva student. They often travel in packs.`,
  },
  BOOBAH: {
    url: 'https://jel.jewish-languages.org/words/85',
    definition: `Sweet term of endearment. Literally "doll" in Hebrew. "Hannah, I know you're jealous of your little brother, but NO HITTING the boobah."`,
  },
  BORSHT: {
    url: 'https://jel.jewish-languages.org/words/75',
    definition: `That red stuff. An Eastern European soup usually made from beets. Careful, it stains!`,
  },
  BRACHA: {
    url: 'https://jel.jewish-languages.org/words/81',
    definition: `A blessing on your head! Bracha is Hebrew for 'blessing.'`,
  },
  BSAMIM: {
    url: 'https://jel.jewish-languages.org/words/2087',
    definition: `Sweet-smelling spices used in the Havdalah ceremony marking the end of Shabbat.`,
  },
  BUBBIE: {
    url: 'https://jel.jewish-languages.org/words/85',
    definition: `Yiddish for beloved grandma!`,
  },
  BUBELE: {
    url: 'https://jel.jewish-languages.org/words/1373',
    definition: `Sweetie, darling, cutie pie. "Sweet bubele, let me get the hummus out of your hair."`,
  },
  BUBKES: {
    url: '',
    definition: `Nada. Nuthin'. Bubkes. A Yiddish quintessential.`,
  },
  BUPKIS: {
    url: 'https://jel.jewish-languages.org/words/86',
    definition: `Nada. Nuthin'. Bubkes. A Yiddish quintessential.`,
  },
  CANDLE: {
    url: '',
    definition: `Shabbat, Hanukkah, everything in between - we light candles to usher in our holy days!`,
  },
  CHABAD: {
    url: '',
    definition: `Chabad-Lubavitch is a Hasidic movement known for outreach, with branches all over the world.`,
  },
  CHAGIM: {
    url: 'https://jel.jewish-languages.org/words/89',
    definition: `Holidays, plural of "chag." "So, where are you for the chagim this year?"`,
  },
  CHALLA: {
    url: '',
    definition: `The best kind of bread ever, period. Traditionally braided and eaten on Shabbat (but why stop there?).`,
  },
  CHAMSA: {
    url: 'https://jel.jewish-languages.org/words/1477',
    definition: `Hand-shaped good luck amulet popular in Judeo-Arabic cultures.`,
  },
  CHARIF: {
    url: 'https://jel.jewish-languages.org/words/2187',
    definition: `Hot hot hot! Modern Hebrew word referring to hot sauce or just spiciness in general.`,
  },
  CHASID: {
    url: 'https://jel.jewish-languages.org/words/222',
    definition: `Member of a Chasidic (ultra-Orthodox) sect. Often used to mean a righteous, pious person.`,
  },
  CHATAN: {
    url: '',
    definition: `Chatan is the Hebrew word for 'groom.' Mazal tov!`,
  },
  CHAVAL: {
    url: 'https://jel.jewish-languages.org/words/100',
    definition: `Too bad, so sad! Hebrew word meaning "aww, what a shame."`,
  },
  CHAVER: {
    url: 'https://jel.jewish-languages.org/words/1522',
    definition: `Friend, buddy, pal. Modern Hebrew. Can also mean member.`,
  },
  CHAZAL: {
    url: 'https://jel.jewish-languages.org/words/1737',
    definition: `Umbrella term for all historic Jewish scholars. Literally, an acronym meaning "our sages of blessed memory."`,
  },
  CHAZAN: {
    url: 'https://jel.jewish-languages.org/words/104',
    definition: `Also spelled Hazzan or Chazzan, they sing and lead prayers with their beautiful voices.`,
  },
  CHAZIR: {
    url: 'https://jel.jewish-languages.org/words/107',
    definition: `Treif. The Hebrew word for pig meat. It can also refer to a boorish, greedy person.`,
  },
  CHEDER: {
    url: 'https://jel.jewish-languages.org/words/1814',
    definition: `No, not the cheese. Cheder is a name for Hebrew school for littles.`,
  },
  CHEREM: {
    url: 'https://jel.jewish-languages.org/words/109',
    definition: `The bad place. Cherem is a state of excommunication used as a communal punishment.`,
  },
  CHESED: {
    url: 'https://jel.jewish-languages.org/words/1679',
    definition: `Kindness, goodness. An act of chesed = an act of lovingkindness.`,
  },
  CHEVRA: {
    url: 'https://jel.jewish-languages.org/words/111',
    definition: `My friends! Your chevra is your crew, your people, your social group. Time to mingle.`,
  },
  CHIDON: {
    url: 'https://jel.jewish-languages.org/words/1881',
    definition: `For some reason we needed a very specific word for a Bible trivia contest and "chidon" is it.`,
  },
  CHIYUV: {
    url: 'https://jel.jewish-languages.org/words/112',
    definition: `Obligation. "In this house, you have a chiyuv to say Shema every morning and take out the garbage every night."`,
  },
  CHIZUK: {
    url: 'https://jel.jewish-languages.org/words/2106',
    definition: `Strengthen, encourage, uplift. "After my bubbe died, I got so much chizuk from reading her Book of Psalms."`,
  },
  CHOLEH: {
    url: 'https://jel.jewish-languages.org/words/1813',
    definition: `A person who is sick. "Stop complaining. We're going to Aunt Diane's because it's a mitzvah to visit a choleh."`,
  },
  CHRAIN: {
    url: 'https://jel.jewish-languages.org/words/1976',
    definition: `Yiddish word for horseradish. Whether white or red chrain is better on matzah is a matter of deep contention for many Jewish families.`,
  },
  CHUMRA: {
    url: 'https://jel.jewish-languages.org/words/117',
    definition: `A religious stringency, being extra strict on a law.`,
  },
  CHUPAH: {
    url: 'https://jel.jewish-languages.org/words/118',
    definition: `Mazal tov! We celebrate weddings under the chupah, a canopy representing a couple's future home.`,
  },
  DAYENU: {
    url: 'https://jel.jewish-languages.org/words/130',
    definition: `Literally, "it would have been enough." You know this Hebrew phrase from the seder song.`,
  },
  DAYYAN: {
    url: 'https://jel.jewish-languages.org/words/1778',
    definition: `Judge. A highly respected rabbinic role, a dayyan makes judgements on divorces, conversions and other important religious matters.`,
  },
  DERECH: {
    url: 'https://jel.jewish-languages.org/words/131',
    definition: `Literally, "way", "road", or "path." Often it means you've gone off (or on) the religious rails. "Oh, Raizel? She's totally off the derech - I saw her eating a cheeseburger on Shabbos."`,
  },
  DOOGMA: {
    url: 'https://jel.jewish-languages.org/words/138',
    definition: `Example, model. "Shira, be a good doogma for your cousin and eat your kugel without throwing it on the floor."`,
  },
  DRASHA: {
    url: 'https://jel.jewish-languages.org/words/135',
    definition: `Sermon given during services. I.e. the perfect time to leave shul for a chat outside.`,
  },
  DUCHEN: {
    url: 'https://jel.jewish-languages.org/words/1827',
    definition: `When kohanim (men descended from the priestly class) give the priestly blessing in synagogue.`,
  },
  DVARIM: {
    url: 'https://jel.jewish-languages.org/words/133',
    definition: `Hebrew word for the Book of Deuteronomy (what does THAT word even mean?)`,
  },
  DYBBUK: {
    url: 'https://jel.jewish-languages.org/words/142',
    definition: `Watch out for this guy. A dybbuk is a demon or lost spirit looking to possess a body.`,
  },
  EICHAH: {
    url: 'https://jel.jewish-languages.org/words/144',
    definition: `O woe! Eicha is the Hebrew name of the very sad Book of Lamentations, read on Tisha B'Av.`,
  },
  ELIJAH: {
    url: '',
    definition: `Your friendly neighbourhood prophet who pops in for every Passover seder!`,
  },
  ELOHIM: {
    url: 'https://jel.jewish-languages.org/words/148',
    definition: `Yet another of our many, many names for God.`,
  },
  EMUNAH: {
    url: 'https://jel.jewish-languages.org/words/150',
    definition: `Don't stop believing! Emuna is the Hebrew word for faith.`,
  },
  ETROGS: {
    url: '',
    definition: `Are they lemons? Limes? No, they're etrogs! These yummy-smelling citrus fruits are one of the Sukkot Four Species.`,
  },
  FARFEL: {
    url: 'https://jel.jewish-languages.org/words/1826',
    definition: `One of those Jewish food words that kind of defies definition but you know what it is. Little noodles or broken up matzah.`,
  },
  FARHUD: {
    url: '',
    definition: `Jews were violently expelled from Baghdad in this 1941 pogrom known in Arabic as 'Farhud.'`,
  },
  FLEISH: {
    url: '',
    definition: `Short for 'fleishig', Yiddish for 'meat'. 'Hey Yankel, you want dairy or fleish for dinner?'`,
  },
  GABBAI: {
    url: 'https://jel.jewish-languages.org/words/176',
    definition: `Lay leader who calls up honorees to the Torah during the Shabbat service. Usually very busy.`,
  },
  GARTEL: {
    url: 'https://jel.jewish-languages.org/words/1658',
    definition: `A cloth belt worn by mostly Hasidic men for prayer which symbolically separates the heart from the, er, downstairs area.`,
  },
  GELILA: {
    url: 'https://jel.jewish-languages.org/words/2242',
    definition: `Back to the ark! Gelila is the act of rolling and and dressing the Torah scroll after reading.`,
  },
  GEMARA: {
    url: 'https://jel.jewish-languages.org/words/185',
    definition: `The Gemara is the portion of the Talmud (Oral Law) containing commentary on the Mishnah.`,
  },
  GENIZA: {
    url: 'https://jel.jewish-languages.org/words/2024',
    definition: `Repository for papers and texts with God's name, which can't be destroyed.`,
  },
  GEVALT: {
    url: 'https://jel.jewish-languages.org/words/191',
    definition: `Oy gevalt! This impossible-to-translate Yiddish word is an expression of shock, alarm or just... gevalt!`,
  },
  GEZUNT: {
    url: 'https://jel.jewish-languages.org/words/193',
    definition: `Health. But this Yiddish word also just has a positive vibe - think robust, strong, resilient. Zai gezunt, be healthy!`,
  },
  GIMMEL: {
    url: '',
    definition: `Gimmel is the third letter in the Hebrew Aleph-Bet, and an important letter on the Dreidle.`,
  },
  GIYYUR: {
    url: 'https://jel.jewish-languages.org/words/1607',
    definition: `Converting to Judaism. "Baruch Hashem, Benjamin's giyyur will be finished in just a few months."`,
  },
  GOYISH: {
    url: 'https://jel.jewish-languages.org/words/203',
    definition: `Something really not Jewish, like drinking milk with dinner or, according to Lenny Bruce, lime soda.`,
  },
  GROGER: {
    url: 'https://jel.jewish-languages.org/words/205',
    definition: `Noisemaker to drown out Haman's name during the Purim megillah reading. Starts out fun, gets annoying real fast.`,
  },
  HACHAM: {
    url: 'https://jel.jewish-languages.org/words/1375',
    definition: `A wise, learned person. "Take Rabbi Yossi's advice, he's a major hacham."`,
  },
  HAGADA: {
    url: 'https://jel.jewish-languages.org/words/211',
    definition: `Book of liturgy, stories, prayers and rituals used at a Passover seder. Comes in almost infinite varieties including Harry Potter-themed.`,
  },
  HAGBAH: {
    url: '',
    definition: `Raise the roof! Er, I mean the Torah! After the Torah portion is read, the scroll is lifted for the congregation to see during Hagbah.`,
  },
  HAKAFA: {
    url: 'https://jel.jewish-languages.org/words/213',
    definition: `To parade around, usually with the Torah on Simchat Torah.`,
  },
  HALAVI: {
    url: 'https://jel.jewish-languages.org/words/2204',
    definition: `Dairy, milky. "Are you in the mood for basari (meat) or halavi for dinner?"`,
  },
  HAMETZ: {
    url: 'https://jel.jewish-languages.org/words/92',
    definition: `Leavened food that is very, very forbidden on Passover. "Eeek, get that hametz out of my KFP kitchen!"`,
  },
  HAMISH: {
    url: 'https://jel.jewish-languages.org/words/229',
    definition: `Familiar, homey, cozy, not at all fancy. "Mushky's Shabbos food is simple and hamish but so good."`,
  },
  HANUKA: {
    url: 'https://jel.jewish-languages.org/words/219',
    definition: `Sooo many ways to spell this, so don't hate! Hanuka is the 8-day festival of light celebrating Jewish victory over our enemies.`,
  },
  HAREDI: {
    url: 'https://jel.jewish-languages.org/words/220',
    definition: `Ultra-Orthodox. Picture the Shtiesel family.`,
  },
  HASHEM: {
    url: '',
    definition: `Literally, 'The Name' in Hebrew. How many Jews refer to God.`,
  },
  HAVURA: {
    url: 'https://jel.jewish-languages.org/words/1376',
    definition: `Group of friends. Comes from the Hebrew root "chaver", friend.`,
  },
  HEBREW: {
    url: '',
    definition: `Our holy language! We pray and read the Torah in Hebrew, and millions of Israelis speak modern Hebrew today.`,
  },
  HEFKER: {
    url: 'https://jel.jewish-languages.org/words/227',
    definition: `Free, available, refers to food rather than people. "Everything on this table is hefker - take whatever you want!"`,
  },
  HESPED: {
    url: 'https://jel.jewish-languages.org/words/2120',
    definition: `Eulogy. "The rabbi gave such a nice hesped for Uncle Mordechai."`,
  },
  HILLEL: {
    url: 'https://jel.jewish-languages.org/words/1713',
    definition: `Your campus go-to for Jewish singles mixers, Shabbat dinners and Purim parties. Named for the famous Torah scholar.`,
  },
  HILONI: {
    url: 'https://jel.jewish-languages.org/words/2348',
    definition: `Secular, not religious. "Tel Aviv is mixed - some dati (religious) people, but mostly hiloni."`,
  },
  HINUCH: {
    url: 'https://jel.jewish-languages.org/words/1848',
    definition: `Jewish education! So important for our young'uns.`,
  },
  HUMMUS: {
    url: '',
    definition: `Made from chickpeas, this essential Mediterranean spread is a Shabbat table favourite.`,
  },
  HUTZPA: {
    url: 'https://jel.jewish-languages.org/words/119',
    definition: `The NERVE of that girl, asking for a discount on top of the sale! Hutzpa is audaciousness beyond belief. Can be good or bad.`,
  },
  ISRAEL: { url: '', definition: `The Jewish homeland. Yallah, let's go!` },
  JEWDAR: {
    url: 'https://jel.jewish-languages.org/words/240',
    definition: `Scanning... scanning... Bingo! Your Jewdar is your ability to identify nearby MOT's (Members Of Tribe.)`,
  },
  JEWFRO: {
    url: 'https://jel.jewish-languages.org/words/242',
    definition: `Everyone's dad had one of these in the 1970's. Lots of frizzy curls springing joyously through the air.`,
  },
  JEWISH: {
    url: '',
    definition: `Many things to many people. A religion, a culture, a people.`,
  },
  KABALA: {
    url: 'https://jel.jewish-languages.org/words/243',
    definition: `Jewish mystical tradition. Very mysterious, very esoteric. Go to Tzfat to learn more.`,
  },
  KADIMA: {
    url: 'https://jel.jewish-languages.org/words/246',
    definition: `Forward! Let's go. Hebrew. Also the name of a center-left Israeli political party.`,
  },
  KADISH: {
    url: 'https://jel.jewish-languages.org/words/1321',
    definition: `Prayer of mourning. Saying kadish for a loved one can be a meaningful and emotional experience for a Jew.`,
  },
  KALLAH: {
    url: '',
    definition: `Kallah is the Hebrew word for 'bride.' Mazal tov!`,
  },
  KAPARA: {
    url: 'https://jel.jewish-languages.org/words/1743',
    definition: `A minor mishap that serves as an "atonement" so you don't get seriously punished.`,
  },
  KARPAS: {
    url: '',
    definition: `Featured on the Passover seder plate, karpas, often represented by parsley or celery, symbolises springtime.`,
  },
  KASHER: {
    url: 'https://jel.jewish-languages.org/words/2315',
    definition: `The act of making an appliance, cooking vessel or utensil kosher.`,
  },
  KAVANA: {
    url: 'https://jel.jewish-languages.org/words/251',
    definition: `Literally, intention. Usually refers to concentration and attention to prayer. "She davens with such kavana."`,
  },
  KEPPIE: {
    url: 'https://jel.jewish-languages.org/words/255',
    definition: `Watch your keppie, little one! Keppie is Yiddish for "head."`,
  },
  KESHER: {
    url: 'https://jel.jewish-languages.org/words/1614',
    definition: `Connection. "Can you get me Diana's number? I really felt a kesher with her at Shabbos dinner."`,
  },
  KETUBA: {
    url: 'https://jel.jewish-languages.org/words/256',
    definition: `Jewish marriage contract. Lots of people get pretty, decorative versions though the text is pretty dry.`,
  },
  KIBITZ: {
    url: 'https://jel.jewish-languages.org/words/258',
    definition: `Chat, shmooze, shoot the breeze. "I only go to shul to kibitz."`,
  },
  KINDER: {
    url: '',
    definition: `Yiddish word for 'children.' You might have heard your bubbe yelling this word a lot.`,
  },
  KIPPAH: {
    url: '',
    definition: `A head covering traditionally worn by Jewish men, it comes in every colour, size, and fabric imaginable.`,
  },
  KIPPUR: {
    url: 'https://jel.jewish-languages.org/words/1868',
    definition: `Yom Kippur, the Day of Atonement. Kippur derives from the Hebrew kapara, or atonement.`,
  },
  KISHKE: {
    url: 'https://jel.jewish-languages.org/words/267',
    definition: `Yiddish. Guts, intestines. Also refers to a sausage stuffed into a casing made of - you guessed it - intestines.`,
  },
  KISLEV: {
    url: '',
    definition: `This Hebrew month generally falls in November/December and contains Hanukkah, a miracle!`,
  },
  KITTEL: {
    url: 'https://jel.jewish-languages.org/words/1589',
    definition: `Long white robe men wear at their weddings and on holidays. It is also used as a burial shroud.`,
  },
  KNEIDL: {
    url: '',
    definition: `What bowl of chicken soup is complete without a kneidl (also known as a matzah ball)? YUM!`,
  },
  KODESH: {
    url: '',
    definition: `Holy, holy, holy! Kodesh is Hebrew for 'holy' and you'll hear it often during prayer.`,
  },
  KOHEIN: {
    url: 'https://jel.jewish-languages.org/words/1834',
    definition: `High priest responsible for Temple rituals. Men with a family tradition of descent from the Kohanim perform certain blessings for the congregation.`,
  },
  KOLLEL: {
    url: 'https://jel.jewish-languages.org/words/1427',
    definition: `A yeshiva where married men learn Torah, and often get paid to do so. Ka-ching.`,
  },
  KOSHER: {
    url: '',
    definition: `OK to eat! No milk with meat, no pork or shellfish - Kosher food is made according to strict Jewish dietary laws.`,
  },
  KRIYAH: {
    url: 'https://jel.jewish-languages.org/words/1473',
    definition: `Literally, "reading" in Hebrew. Often refers to the Torah reading. Can also mean tearing of a garment when one is in mourning.`,
  },
  KTUVIM: {
    url: 'https://jel.jewish-languages.org/words/2393',
    definition: `Writings. The last section of Tanach (Torah, Neviim and Ktuvim).`,
  },
  KUMKUM: {
    url: 'https://jel.jewish-languages.org/words/286',
    definition: `A kettle for heating water. For some reason this Hebrew word has crossed into regular Jewish-English use.`,
  },
  KVATER: {
    url: 'https://jel.jewish-languages.org/words/288',
    definition: `A role of honor at a baby's bris, or ritual circumcision. The kvater carries the baby into and out of the ceremony.`,
  },
  KVETCH: {
    url: '',
    definition: `Yiddish. To kvetch is to whine, complain, gripe, moan and generally annoy the heck out of your parents.`,
  },
  KVUTZA: {
    url: 'https://jel.jewish-languages.org/words/1890',
    definition: `Group, section, unit. Usually having to do with Jewish summer camp and youth movements.`,
  },
  LADINO: {
    url: '',
    definition: `Also known as 'Judeo-Spanish', Ladino is spoken by the descendants of Jews expelled from Spain in the Inquisition.`,
  },
  LASHON: {
    url: 'https://jel.jewish-languages.org/words/315',
    definition: `Hebrew word for language or tongue. A word often paired as "lashon hara" - bad speech.`,
  },
  LATKES: {
    url: '',
    definition: `Potato pancakes fried in oil to celebrate the miracle of Hanukkah (and definitely not because fried food is delicious).`,
  },
  LCHAIM: {
    url: '',
    definition: `To life! Immortalised in the 'Fiddler on the Roof' song, this Hebrew toast is often said over drinks.`,
  },
  LEVAYA: {
    url: 'https://jel.jewish-languages.org/words/305',
    definition: `Funeral. Interestingly this word is derived from the Hebrew verb meaning to accompany, or escort.`,
  },
  LIMMUD: {
    url: 'https://jel.jewish-languages.org/words/2352',
    definition: `Hebrew for "learning", one of our major Jewish pasttimes.`,
  },
  LULAVS: {
    url: '',
    definition: `East, south, west, north, up and down - we shake lulavs, or palm fronds, on Sukkot to celebrate the harvest.`,
  },
  MAARIV: {
    url: '',
    definition: `Maariv, the evening prayer, is the third and final service of the day, recited after sunset.`,
  },
  MABRUK: {
    url: 'https://jel.jewish-languages.org/words/1510',
    definition: `The Sephardi version of Mazal Tov, derived from Arabic. "You're getting married? Mabruk!"`,
  },
  MACHER: {
    url: 'https://jel.jewish-languages.org/words/325',
    definition: `Yiddish. A big guy, a big deal, knows everyone, you know the type. Big Macher on Campus.`,
  },
  MAHANE: {
    url: 'https://jel.jewish-languages.org/words/324',
    definition: `Biblically, this Hebrew word means "camp." Today, it means summer camp. Colour War anyone?`,
  },
  MAIDEL: {
    url: 'https://jel.jewish-languages.org/words/331',
    definition: `Pshh, what a shayna maidel! Yiddish for "girl."`,
  },
  MAKPID: {
    url: 'https://jel.jewish-languages.org/words/1465',
    definition: `Not a type of crawling bug. Particular, consciencious, strict. "He's very makpid on kashrut."`,
  },
  MAMASH: {
    url: 'https://jel.jewish-languages.org/words/335',
    definition: `A lot, very, sooo much, totally. "You mamash sing like Barbara Streisand."`,
  },
  MAMZER: {
    url: 'https://jel.jewish-languages.org/words/336',
    definition: `Err, not a very nice word. Hebrew and Yiddish for "bastard."`,
  },
  MANNAH: {
    url: 'https://jel.jewish-languages.org/words/338',
    definition: `Mannah from heaven! The food God sent to feed the wandering Israelites. Yum.`,
  },
  MARROR: {
    url: 'https://jel.jewish-languages.org/words/1314',
    definition: `Bitter herbs, one of the stars of the seder plate. "Halaila hazeh, marror!"`,
  },
  MASHAL: {
    url: 'https://jel.jewish-languages.org/words/388',
    definition: `A story, example. "Let me tell you the mashal of Honi the Circlemaker."`,
  },
  MATZAH: {
    url: '',
    definition: `Unleavened bread/cracker/cardboard commemorating the Hebrews' mad dash out of Egypt.`,
  },
  MATZAV: {
    url: 'https://jel.jewish-languages.org/words/343',
    definition: `Hebrew word for "situation." "So tell me, what's the matzav?"`,
  },
  MENORA: {
    url: 'https://jel.jewish-languages.org/words/1587',
    definition: `Usually means the eight-branched candelabra used in the Temple, but also refers to the nine-branched hanukiah lit on Hanuka.`,
  },
  MENSCH: {
    url: '',
    definition: `Yiddish word for a really good guy. You know Moshe? What a mensch.`,
  },
  MERKAZ: {
    url: 'https://jel.jewish-languages.org/words/365',
    definition: `Center, like of a city. "Hey, you want to go to the mercaz later? Ben Yehudah will be hopping tonight."`,
  },
  MESORA: {
    url: 'https://jel.jewish-languages.org/words/368',
    definition: `A long-held tradition. "Sephardic Jews have the mesorah of eating rice on Passover."`,
  },
  MEZUZA: {
    url: '',
    definition: `A tiny scroll inscribed with Torah verses, tucked into a pretty case and affixed to Jewish doorposts.`,
  },
  MIDDOT: {
    url: 'https://jel.jewish-languages.org/words/373',
    definition: `Character traits. "You should date Yosef - he has such good middot."`,
  },
  MIKVEH: {
    url: '',
    definition: `Ritual bath used by men, women and also frying pans. Not kidding, look it up.`,
  },
  MIMUNA: {
    url: 'https://jel.jewish-languages.org/words/1683',
    definition: `Delicious end-of-Passover feast of chametz foods traditional among Moroccan Jews. Bring on the moufleta!`,
  },
  MINCHA: {
    url: '',
    definition: `The second of the three daily prayer services, recited in the afternoon.`,
  },
  MINHAG: {
    url: 'https://jel.jewish-languages.org/words/379',
    definition: `Tradition, tradition! A minhag is a Jewish custom or practice, not necessarily a law but we do it anyway.`,
  },
  MINYAN: {
    url: '',
    definition: `A quorum of ten required for reciting certain prayers like Kaddish.`,
  },
  MISHNA: {
    url: '',
    definition: `The Mishna is the core of the Talmud, or Oral Law, containing classic Jewish rabbinical teachings.`,
  },
  MITZVA: {
    url: 'https://jel.jewish-languages.org/words/386',
    definition: `A commandment from the Torah. Colloquially also used to mean a good deed. "What a mitzva!"`,
  },
  MOADON: {
    url: 'https://jel.jewish-languages.org/words/1641',
    definition: `Members only! A moadon is a club or clubhouse in Hebrew.`,
  },
  MUKTZA: {
    url: 'https://jel.jewish-languages.org/words/390',
    definition: `An object that is forbidden on Shabbat or yontif. "Chani, put that muktza iPad away right now!"`,
  },
  MUSSAF: {
    url: 'https://jel.jewish-languages.org/words/391',
    definition: `Additional prayer service said on Shabbat and holidays. Because who doesn't need more prayer?`,
  },
  MUTTAR: {
    url: 'https://jel.jewish-languages.org/words/1831',
    definition: `Permitted, allowed in Jewish law. "Pork is assur (forbidden), but lamb is muttar."`,
  },
  NACHAS: {
    url: 'https://jel.jewish-languages.org/words/394',
    definition: `Pride and joy. How we feel watching our kids become bat or bar mitzvah, get married, or win a Nobel Prize.`,
  },
  NACHON: {
    url: 'https://jel.jewish-languages.org/words/1704',
    definition: `Right on. Hebrew word meaning correct or true.`,
  },
  NEBACH: {
    url: 'https://jel.jewish-languages.org/words/399',
    definition: `Pathetic, sad, losery, unfortunate. Yiddish of course.`,
  },
  NEBISH: {
    url: 'https://jel.jewish-languages.org/words/1532',
    definition: `A nerdy, clumsy, awkward person. Yiddish, what else?`,
  },
  NEGIAH: {
    url: 'https://jel.jewish-languages.org/words/401',
    definition: `Touch. Often appears in the phrase "shomer negiah", meaning the practice not to touch people of the opposite gender.`,
  },
  NIDCHE: {
    url: 'https://jel.jewish-languages.org/words/1849',
    definition: `Delayed, pushed off, rescheduled. The fate of a fast day that falls on Shabbat.`,
  },
  NIDDAH: {
    url: 'https://jel.jewish-languages.org/words/408',
    definition: `The state of menstrual impurity and the nuanced laws surrounding it.`,
  },
  NIFTAR: {
    url: 'https://jel.jewish-languages.org/words/409',
    definition: `Dead, deceased. "My bubbe was niftar last month."`,
  },
  NIGGUN: {
    url: 'https://jel.jewish-languages.org/words/410',
    definition: `Ay-ay-ay-oy-oy-oy! A wordless melody or tune, sung on its own or applied to a song or prayer.`,
  },
  NISSAN: {
    url: '',
    definition: `Freedom! The Hebrew month of Nissan usually falls in March-April and contains Passover.`,
  },
  NOODGE: {
    url: 'https://jel.jewish-languages.org/words/417',
    definition: `Oy, that Shmulik - such a noodge! Yiddish for an annoying, pesky, naggy person.`,
  },
  NUDNIK: {
    url: 'https://jel.jewish-languages.org/words/418',
    definition: `Ughh, so annoying. A pest, a nag. "That nudnik Shimon keeps harassing me about going to see his band."`,
  },
  PAREVE: {
    url: 'https://jel.jewish-languages.org/words/427',
    definition: `Not dairy, not meat. A neutral food category. Fruit, vegetables, eggs, fish are all pareve.`,
  },
  PARSHA: {
    url: 'https://jel.jewish-languages.org/words/1541',
    definition: `The weekly Torah portion. "Have you learned your Bar Mitzvah parsha yet, Benjamin?"`,
  },
  PASKEN: {
    url: 'https://jel.jewish-languages.org/words/428',
    definition: `To make a Halachic ruling regarding Jewish law. "Rabbi Shmulik paskens that the cookies are kosher - I'm eating them."`,
  },
  PASSUK: {
    url: 'https://jel.jewish-languages.org/words/1666',
    definition: `A verse, usually of Torah. "The rabbi gave such an interesting talk on that passuk."`,
  },
  PASSUL: {
    url: 'https://jel.jewish-languages.org/words/441',
    definition: `Something that is invalid, defective. "I haven't checked my mezuzot in 20 years - they're for sure all passul."`,
  },
  PECKEL: {
    url: 'https://jel.jewish-languages.org/words/432',
    definition: `Little bundle or packet, kind of like a goody bag. Yiddish.`,
  },
  PERUSH: {
    url: 'https://jel.jewish-languages.org/words/1394',
    definition: `Analysis, commentary. All of Rashi's writings are a perush on the Torah. The guy was prolific.`,
  },
  PESACH: {
    url: '',
    definition: `Hebrew for 'Passover.' This 8-day celebration of our redemption from the bonds of slavery involves a different kind of binding.`,
  },
  PESHAT: {
    url: 'https://jel.jewish-languages.org/words/1386',
    definition: `The simple, obvious interpretation. "I know the snake in Eden is an allegory for evil, but what's the peshat?"`,
  },
  PLATTA: {
    url: 'https://jel.jewish-languages.org/words/437',
    definition: `Hot plate used to heat up food on Shabbat and holidays, when cooking isn't permitted.`,
  },
  POGROM: {
    url: '',
    definition: `Riots and massacres carried out against Jewish communities, generally in Eastern Europe in the 1800s and 1900s.`,
  },
  PRAYER: {
    url: '',
    definition: `We do a lot of this. Morning, noon and night, we're sending shout-outs upstairs. No weekends off.`,
  },
  PUPPIK: {
    url: 'https://jel.jewish-languages.org/words/447',
    definition: `Your belly button! Not sure why this Yiddish word became one of the all-stars, but somehow it did.`,
  },
  PUSHKA: {
    url: 'https://jel.jewish-languages.org/words/448',
    definition: `Tzedaka box. Put a little in the pushka each week, then send it all off to the JNF.`,
  },
  RABBIS: {
    url: '',
    definition: `Jewish communal and prayer leaders. They also appear in many jokes.`,
  },
  REFORM: {
    url: 'https://jel.jewish-languages.org/words/1708',
    definition: `Jewish movement based on principles of the Haskalah, or Jewish Enlightenment. "I grew up Conservative but now I identify as Reform."`,
  },
  RIKKUD: {
    url: 'https://jel.jewish-languages.org/words/1318',
    definition: `Get off your tuchas, it's time for rikkud - (Israeli) dancing!`,
  },
  SABABA: {
    url: 'https://jel.jewish-languages.org/words/467',
    definition: `Cool, awesome, fun. Hebrew and Arabic slang. "Shwarma for lunch? Sababa!"`,
  },
  SANDEK: {
    url: '',
    definition: `This guy has a very important role at a bris (circumcision): he needs to be able to sit very, very still. (He holds the baby on his lap.)`,
  },
  SAVTAH: {
    url: 'https://jel.jewish-languages.org/words/471',
    definition: `Hebrew for Grandma. "Come give Savtah a kiss, chamudi (cutie)!"`,
  },
  SCHACH: {
    url: 'https://jel.jewish-languages.org/words/1581',
    definition: `The roofing on a sukkah. Must be made of a natural material like palm fronds or bamboo.`,
  },
  SCHLEP: {
    url: '',
    definition: `Yiddish. You free next weekend to help me schlep a few things? Not much, just a bed, fridge, hot tub, and two pianos.`,
  },
  SCHLUB: {
    url: '',
    definition: `Ugh, did you see the schlub wearing thongs to the opera? A Yiddish word meaning boorish slob.`,
  },
  SCHNOR: {
    url: 'https://jel.jewish-languages.org/words/481',
    definition: `To mooch, get stuff for free. "Avrumi is such a shnorer - he even asks for a discount on petrol."`,
  },
  SCHNOZ: {
    url: 'https://jel.jewish-languages.org/words/527',
    definition: `A fun Yiddish word for nose - often one of impressive dimensions.`,
  },
  SCHTUP: {
    url: 'https://jel.jewish-languages.org/words/1412',
    definition: `Have sex with. Yiddish slang. Badda bing, badda boom.`,
  },
  SCROLL: {
    url: '',
    definition: `You've heard of books? Now try scrolls! Torahs, mezuzahs and megillahs are all written on long, rolled-up pieces of parchment.`,
  },
  SECHEL: {
    url: 'https://jel.jewish-languages.org/words/472',
    definition: `Intelligence, smarts, common sense. Hebrew. "What are you doing in the rain without a coat, use your sechel!"`,
  },
  SEDRAH: {
    url: 'https://jel.jewish-languages.org/words/1698',
    definition: `Another word for parsha, the weekly Torah portion. "Did you hear Yankel leyning the sedra at shul?"`,
  },
  SEGULA: {
    url: 'https://jel.jewish-languages.org/words/1511',
    definition: `Good omen, folk remedy or safeguard. "It's a segula to pray for a shidduch at the grave of a righteous person."`,
  },
  SFORIM: {
    url: 'https://jel.jewish-languages.org/words/487',
    definition: `The building blocks of any Jewish home: books. Books everywhere. Plural of sefer, book.`,
  },
  SHAILA: {
    url: 'https://jel.jewish-languages.org/words/1645',
    definition: `Question, often of a Halachic nature. "I need to ask Rabbi Katz a shaila about my dairy pot, I think I treifed it accidentally."`,
  },
  SHALOM: {
    url: '',
    definition: `Hebrew word meaning hello, goodbye, peace. Very versatile.`,
  },
  SHANDA: {
    url: 'https://jel.jewish-languages.org/words/499',
    definition: `Useful Yiddish word meaning shame, scandal. "His matzoh balls are so bland - what a shanda."`,
  },
  SHECHT: {
    url: 'https://jel.jewish-languages.org/words/501',
    definition: `To ritually slaughter an animal, rendering it kosher. Not an activity for the faint of heart.`,
  },
  SHEKEL: {
    url: 'https://jel.jewish-languages.org/words/508',
    definition: `Cash, silver, benjamins. This biblical unit of money is also the name of Israel's modern currency.`,
  },
  SHEMOT: {
    url: 'https://jel.jewish-languages.org/words/510',
    definition: `Hebrew name for the Book of Exodus. Literally, "Names."`,
  },
  SHERUT: {
    url: 'https://jel.jewish-languages.org/words/511',
    definition: `Literally, Hebrew word for service. Usually, a taxi you take to or from Ben Gurion airport.`,
  },
  SHEVAT: {
    url: '',
    definition: `The Hebrew month of Shevat lands in January/February. We celebrate Tu B'Shevat, the birthday of the trees!`,
  },
  SHIKSA: {
    url: 'https://jel.jewish-languages.org/words/516',
    definition: `A kind of rude word for a non-Jewish woman.`,
  },
  SHIKUR: {
    url: 'https://jel.jewish-languages.org/words/514',
    definition: `Tipsy or drunk. "Avoid Uncle Mordechai - he's shikured off his tuchas." Yiddish.`,
  },
  SHIRAH: {
    url: 'https://jel.jewish-languages.org/words/1410',
    definition: `Song. Often paired with "birah" (beer) for a lovely night of tipsy singing among young Jewish singles.`,
  },
  SHIVAH: {
    url: '',
    definition: `Literally, the number seven. Shivah refers to the week-long mourning period Jews observe following a death.`,
  },
  SHKIAH: {
    url: 'https://jel.jewish-languages.org/words/2246',
    definition: `Sunset. It marks a limit of Halachic time for candlelighting, reciting prayers, and bringing in holidays.`,
  },
  SHLITA: {
    url: 'https://jel.jewish-languages.org/words/2210',
    definition: `An acronym meaning "may he live long and well" - an honorific often added after the name of any respected person or rabbi.`,
  },
  SHLOCK: {
    url: 'https://jel.jewish-languages.org/words/520',
    definition: `Garbage, junk, cheap. Yiddish, what else? "You went all the way to Italy and only brought me back this shlock magnet?"`,
  },
  SHLUMP: {
    url: 'https://jel.jewish-languages.org/words/1630',
    definition: `Unkempt, messy, not-together person. "No way will I agree to a second date - he dressed like such a shlump."`,
  },
  SHMATA: {
    url: 'https://jel.jewish-languages.org/words/477',
    definition: `A rag, or old, crappy garment. "I can't go out tonight, I've got nothing to wear but this ugly shmata."`,
  },
  SHMEAR: {
    url: 'https://jel.jewish-languages.org/words/478',
    definition: `Ahh, the ultimate Jewish combo - bagel and shmear (cream cheese, of course)!`,
  },
  SHMIRA: {
    url: 'https://jel.jewish-languages.org/words/526',
    definition: `Guard duty. Often refers to watching over the body of a deceased person before burial.`,
  },
  SHMUCK: {
    url: 'https://jel.jewish-languages.org/words/502',
    definition: `Jerk, irritating, annoying person. Yiddish. "Yossi hit my fender and refused to pay. He's such a shmuck."`,
  },
  SHMURA: {
    url: 'https://jel.jewish-languages.org/words/1643',
    definition: `A type of matzah which is *particularly* kosher for Passover. Literally, "guarded."`,
  },
  SHMUTZ: {
    url: '',
    definition: `Yiddish. You've got shmutz on your face. Just a tiny bit of dirt, lint, spilled ketchup. You know. Shmutz.`,
  },
  SHNAPS: {
    url: 'https://jel.jewish-languages.org/words/480',
    definition: `Some kind of strong liquor, often in demand at l'chaims or pretty much any Jewish gathering.`,
  },
  SHOFAR: {
    url: '',
    definition: `Ram's horn traditionally blown on Rosh Hashana and Yom Kippur. Not nature's most melodious instrument.`,
  },
  SHOHET: {
    url: 'https://jel.jewish-languages.org/words/2114',
    definition: `Ritual slaughterer learned in the art of shechita, or kosher slaughter. Bloody work.`,
  },
  SHPIEL: {
    url: 'https://jel.jewish-languages.org/words/533',
    definition: `A story or performance. Or occasionally a sales pitch. Everyone loves a Purim shpiel!`,
  },
  SHTARK: {
    url: 'https://jel.jewish-languages.org/words/535',
    definition: `Religious, but like, very. "Ned got really shtark after his year of yeshiva in Israel."`,
  },
  SHTETL: {
    url: 'https://jel.jewish-languages.org/words/538',
    definition: `The quaint Eastern European village your great-grandparents escaped from. Think Fiddler on the Roof.`,
  },
  SHTICK: {
    url: '',
    definition: `Your whole thing. Your gimmick, your routine, your style, your jam. Yiddish, of course.`,
  },
  SHTUSS: {
    url: 'https://jel.jewish-languages.org/words/540',
    definition: `Stuff and nonsense. Yiddish. "Rosie loves Cocomelon but that show is such shtuss."`,
  },
  SHVACH: {
    url: 'https://jel.jewish-languages.org/words/544',
    definition: `Useful Yiddish word meaning shabby, crappy, not nice. "That kitchen is so shvach - the cabinets look 100 years old."`,
  },
  SHVITZ: {
    url: 'https://jel.jewish-languages.org/words/483',
    definition: `To sweat. Yiddish origin. "Oy, turn up the AC - I'm shvitzing my tuches off."`,
  },
  SICHAH: {
    url: 'https://jel.jewish-languages.org/words/2157',
    definition: `In Hebrew, a conversation. Colloquially, a public talk or discussion.`,
  },
  SIDDUR: {
    url: '',
    definition: `Prayer book. Comes in Ashkenazi, Sephardi, Sephard, Mizrach, and Yemenite varieties, plus more.`,
  },
  SIMCHA: {
    url: '',
    definition: `You're getting married? What a simcha! Literally 'happiness' in Hebrew, simcha means a celebration or happy life event.`,
  },
  SIYYUM: {
    url: 'https://jel.jewish-languages.org/words/548',
    definition: `Celebration after completing study of a tractate of Talmud. The best excuse to eat meat during the Nine Days.`,
  },
  SLICHA: {
    url: 'https://jel.jewish-languages.org/words/549',
    definition: `Excuse me, sorry! The single most useful Hebrew phrase you will need on a trip to Israel.`,
  },
  SMICHA: {
    url: 'https://jel.jewish-languages.org/words/551',
    definition: `Rabbinic ordination. "Michael got his smicha from Yeshiva University."`,
  },
  SUGIAH: {
    url: 'https://jel.jewish-languages.org/words/1409',
    definition: `A sugiah is a passage from the Talmud. "Rivky, help me translate this sugiah, it's really difficult."`,
  },
  SUKKAH: {
    url: '',
    definition: `A temporary booth you build in your backyard for the festival of Sukkot. And to confuse your neighbours.`,
  },
  SUKKOT: {
    url: '',
    definition: `Eight-day harvest festival when we live in temporary booths decorated with gourds and fairy lights.`,
  },
  TAHARA: {
    url: 'https://jel.jewish-languages.org/words/2491',
    definition: `Purity. Usually refers to the ritual cleansing of a corpse. Challenging and holy work.`,
  },
  TAHINI: {
    url: 'https://jel.jewish-languages.org/words/2059',
    definition: `Paste made from sesame seeds, a popular condiment in Israeli and Mediterranean cuisine.`,
  },
  TALLIT: {
    url: '',
    definition: `A rectangular, fringed garment worn by Jewish men (and some women) during prayer.`,
  },
  TALMUD: {
    url: '',
    definition: `The Oral Law. A major compendium of Jewish rabbinical thought and commentary written down over thousands of years.`,
  },
  TAMMUZ: {
    url: '',
    definition: `The Hebrew month of Tammuz falls in June-July. Its major themes are mourning, misery and tragedy.`,
  },
  TANACH: {
    url: '',
    definition: `A Hebrew acronym referring to our three major written texts: Torah, Nach (Prophets) and Ketuvim (Writings).`,
  },
  TATTIE: {
    url: 'https://jel.jewish-languages.org/words/1408',
    definition: `Dad, father. "Mom said I can't have cookies for dinner, but Tattie said I can."`,
  },
  TEFILA: {
    url: 'https://jel.jewish-languages.org/words/568',
    definition: `Prayer or prayer service. "Yossi, if you miss the bus you'll be late for tefila!"`,
  },
  TEMPLE: {
    url: '',
    definition: `The Temple, which once (twice actually) stood in Jerusalem, was the center of Jewish life and ritual.`,
  },
  TICHEL: {
    url: 'https://jel.jewish-languages.org/words/1870',
    definition: `Sounds like a sneeze but it's the head covering worn by many religious married women.`,
  },
  TIKKUN: {
    url: 'https://jel.jewish-languages.org/words/2405',
    definition: `Literally, fixing. Think "tikkun olam", fixing the world. Also the name of a book which helps you learn to read the Torah aloud.`,
  },
  TIYYUL: {
    url: 'https://jel.jewish-languages.org/words/1876',
    definition: `Yallah, join me for a tiyyul up the mountain! A trip, hike, walk, journey. Always fun.`,
  },
  TSHUVA: {
    url: 'https://jel.jewish-languages.org/words/573',
    definition: `Literally, returning. It means repentence. A Baal Tshuva is a non-religious person who becomes observant.`,
  },
  TSURIS: {
    url: 'https://jel.jewish-languages.org/words/585',
    definition: `Oyy, you wouldn't believe the tsuris from that daughter of mine! Tsuris is trouble, problems, worries, in Yiddish.`,
  },
  TUCHES: { url: '', definition: `You tushy. Your butt. Yiddish, obviously.` },
  TZADIK: {
    url: '',
    definition: `A very righteous guy. Like a mensch, but even better.`,
  },
  TZAHAL: {
    url: 'https://jel.jewish-languages.org/words/592',
    definition: `Hebrew acryonym for IDF - the Israeli Defense Force.`,
  },
  TZEDEK: {
    url: 'https://jel.jewish-languages.org/words/2226',
    definition: `Justice, justice shall you pursue!`,
  },
  TZIBUR: {
    url: 'https://jel.jewish-languages.org/words/1529',
    definition: `The Jewish community, the public, the people. "Do it for the good of the tzibur!"`,
  },
  TZIMES: {
    url: 'https://jel.jewish-languages.org/words/2013',
    definition: `A sweet, often carrot-based stew traditionally served on Rosh Hashana. Yum yum.`,
  },
  TZNIUS: {
    url: 'https://jel.jewish-languages.org/words/595',
    definition: `Modest, discreet. "No, I don't think getting a lower back tattoo would be very tznius."`,
  },
  TZURIS: { url: '', definition: `Yiddish word meaning trouble, problems.` },
  YALLAH: {
    url: '',
    definition: `Come on, let's go! This Hebrew slang is actually Arabic, but has been co-opted by Birthright group leaders.`,
  },
  YICHUS: {
    url: 'https://jel.jewish-languages.org/words/618',
    definition: `Your family connections, your Jewish pedigree. Got a big rav in your family tree? You've got yichus.`,
  },
  YIGDAL: {
    url: 'https://jel.jewish-languages.org/words/1631',
    definition: `A melodic hymn sung at the end of services. Often led by kids in the congregation.`,
  },
  YIZKOR: {
    url: '',
    definition: `Literally, 'He will remember.' A memorial prayer for the dead recited in Ashkenazi synagogues on major holidays.`,
  },
  YONTIF: {
    url: 'https://jel.jewish-languages.org/words/626',
    definition: `Holiday. Yiddish word derived from the Hebrew "Yom Tov". "Going to your bubbe's for Yontif?"`,
  },
  YOTZEI: {
    url: 'https://jel.jewish-languages.org/words/1667',
    definition: `To have fulfilled one's obligation, or to be exempt. "I'm yotzei - I already davened mincha."`,
  },
  ZAATAR: {
    url: 'https://jel.jewish-languages.org/words/633',
    definition: `Quintessential Israeli/Mediterranean spice made from oregano, basil, thyme, and savory. Yum yum.`,
  },
  ZAFTIG: {
    url: 'https://jel.jewish-languages.org/words/630',
    definition: `The opposite of svelte. A little bit chunky, pleasantly plump. Yiddish.`,
  },
  ZEROAH: {
    url: 'https://jel.jewish-languages.org/words/2400',
    definition: `The roasted shank bone adorning the Seder plate on Passover, representing the Pascal lamb.`,
  },
  ZIVVUG: {
    url: 'https://jel.jewish-languages.org/words/1742',
    definition: `Soul mate, match, other half. "I hope this is this the year I find my zivvug - I'm sick of my mom asking me when she's getting grandkids."`,
  },
  SIDUR: {
    url: 'https://jel.jewish-languages.org/words/546',
    definition: `A Jewish prayerbook - every morning I read prayers from the sidur. `,
  },
  TEFILAH: {
    url: 'https://jel.jewish-languages.org/words/568',
    definition: `Prayer, services or an indivdual prayer. "We had an epic tefilah this Shabbas.`,
  },
  FRIER: {
    url: 'https://jel.jewish-languages.org/words/2807',
    definition: `A 'sucker' - someone who is easily taken advantage of.  " I’m not going to be the frayer who stays in the slow lane."`,
  },
  SCHUG: {
    url: 'https://jel.jewish-languages.org/words/2230',
    definition: `A Middle Eastern spicy sauce made from fresh hot peppers seasoned with coriander, garlic, and various spices. "Oy that schug at dinner last night was insane--my mouth is on fire!"`,
  },
  SHMALTZ: {
    url: 'https://jel.jewish-languages.org/words/475',
    definition: `Rendered chicken fat used for cooking. "The secret ingredient in Bobba's kneidlach? They're full of shmaltz!"`,
  },
  GROGGER: {
    url: 'https://jel.jewish-languages.org/words/205',
    definition: `A noise maker used on Purim to drown out the name of Haman or something that makes a lot of noise. "My neighbours are renovating - it's power tools all day - a noise is worse than 1,000 groggers!"`,
  },
  HAGADAH: {
    url: 'https://jel.jewish-languages.org/words/211',
    definition: `The small book of liturgy, prayers, songs, and rituals used at a Pesach seder. "Don't go to Mendy for First Night he reads the whole haggadah - no skipping!"`,
  },
  TEFILIN: {
    url: 'https://jel.jewish-languages.org/words/570',
    definition: `Small black boxes containing sections of the Shema attached to leather straps and worn around the head and arm during prayer. "Come join me to put on Tefilin this morning - it's a mitzvah." `,
  },
  TALIT: {
    url: 'https://jel.jewish-languages.org/words/560',
    definition: `Prayer shawl. "Oy gevalt! I forgot to bring my talit to services."`,
  },
  SHLEP: {
    url: 'https://jel.jewish-languages.org/words/474',
    definition: `To travel from one place to another when one would prefer not to, an annoyingly long journey or to carry or lug something. "We shelpped all the way to Bondi, shlepped enough food for a Barmitzvah, and a million pecklach and it was raining."`,
  },
  SHMUK: {
    url: 'https://jel.jewish-languages.org/words/502',
    definition: `An annoying, irritating person (and literally a penis). "Don't date Brian - he's such a shmuck." `,
  },
  MEGILAH: {
    url: 'https://jel.jewish-languages.org/words/355',
    definition: `The Book of Esther (specifically) or a long, engrossing story or description. "Ugh she talked the whole night, every part of the trip, the cruise ship, the number of pools, the restaurants, the entertainment- the whole megillah!"`,
  },
  SCHMUTZ: {
    url: 'https://jel.jewish-languages.org/words/503',
    definition: `Dirt, stain, or filth. "Look at the shmutz on that shirt - I can't take you anywhere."`,
  },
  BABKA: {
    url: 'https://jel.jewish-languages.org/words/34',
    definition: `A loaf type coffee cake. "You should taste her babka - she makes it with Nutella".`,
  },
  BAGEL: {
    url: 'https://jel.jewish-languages.org/words/35',
    definition: `A doughnut shaped roll. "Come meet me at the deli - they serve the best bagel and lox in town"`,
  },
  BALAGAN: {
    url: 'https://jel.jewish-languages.org/words/41',
    definition: `A disorderly, confusing, and/or overwhelming situation/mess. "The concert at school was a balagan". `,
  },
  BASHERT: {
    url: 'https://jel.jewish-languages.org/words/45',
    definition: `Predestined, fated, can refer to a soul mate. "Oh I'm happy I met Miriam - she's my bashert". `,
  },
  TANYA: {
    url: 'https://jel.jewish-languages.org/words/2155',
    definition: `The foundational text of Chabad Lubavitch written by the founder of Chabad, Rabbi Shneur Zalman of Liadi. "Such nachas from my Mushka - she won the competition - her knowledge of Tanya was outstanding."`,
  },
  BESEDER: {
    url: 'https://jel.jewish-languages.org/words/61',
    definition: `Alright; fine. "Are we ok now?" "Beseder" `,
  },
  BIMAH: {
    url: 'https://jel.jewish-languages.org/words/65',
    definition: `A raised platform or stage in a synagogue from which the Torah is read and services are led. "The Bar Mitzvah boys sang beautifully on the bimah". `,
  },
  BLECH: {
    url: 'https://jel.jewish-languages.org/words/71',
    definition: `An electric hot plate or sheet of metal that can be placed over the burners on a stove to retain heat, so that precooked food...can be kept warm for Shabbas. "Get the cholent off the blech please, Shayni". `,
  },
  BORSCHT: {
    url: 'https://jel.jewish-languages.org/words/75',
    definition: `An Eastern European soup traditionally made with beets. "My Bubbe's borscht is delicious'"`,
  },
  BUBBE: {
    url: 'https://jel.jewish-languages.org/words/85',
    definition: `Grandmother. "Phone your Bubbe please, otherwise she'll think you don't love you." `,
  },
  CHALLAH: {
    url: 'https://jel.jewish-languages.org/words/91',
    definition: `Braided bread that traditionally is eaten on almost every Jewish holiday and Shabbas. "We need two challahs from Jesses and a gluten-free one for Maya."`,
  },
  CHAMETZ: {
    url: 'https://jel.jewish-languages.org/words/92',
    definition: `Leavened food. "We cleaned the whole house before Pesach and made sure there was not a crumb of chametz." `,
  },
  CHOLENT: {
    url: 'https://jel.jewish-languages.org/words/114',
    definition: `A slow-cooked stew made of beef, beans, potatoes, and other vegetables. A common Sabbath dish. "Rivky makes the best cholent with beans, meat and shmaltz."`,
  },
  CHUMASH: {
    url: 'https://jel.jewish-languages.org/words/116',
    definition: `A bound book containing the Five Books of Moses that is used in synagogue or for study. "You can use the chumash in shul I'm just going to catch-up with Shirley."`,
  },
  CHUMRAH: {
    url: 'https://jel.jewish-languages.org/words/117',
    definition: `A religious stringency. "Not eating matzah that's come in to contact with liquid isn't halachah, it's a chumrah."`,
  },
  CHUPPAH: {
    url: 'https://jel.jewish-languages.org/words/118',
    definition: `Wedding ceremony (not including reception), the Jewish wedding canopy. "Mazaltov! Are you going to the wedding? "Just the chuppah". `,
  },
  DAVEN: {
    url: 'https://jel.jewish-languages.org/words/127',
    definition: `Pray, lead services. "So nu? Who's davening mincha tomorrow?"`,
  },
  DAVKA: {
    url: 'https://jel.jewish-languages.org/words/128',
    definition: `Even; despite expectations to the contrary or just to annoy or be contrary. "Don't invite Shlomo, he's so davka."`,
  },
  DEVARIM: {
    url: 'https://jel.jewish-languages.org/words/133',
    definition: `The Book of Deuteronomy. `,
  },
  DRASH: {
    url: 'https://jel.jewish-languages.org/words/134',
    definition: `Sermon. "That was a divisive drash about the elections"`,
  },
  DRECK: {
    url: 'https://jel.jewish-languages.org/words/136',
    definition: `Trash, junk, garbage; cheap or worthlesss things. "The music the kids listen to these days is such dreck". `,
  },
  DREIDEL: {
    url: 'https://jel.jewish-languages.org/words/137',
    definition: `A small, four-sided spinning top used in a game during Chanukah. "Playing dreidel,is my gelty pleasure"`,
  },
  DUGMA: {
    url: 'https://jel.jewish-languages.org/words/138',
    definition: `Example. "Rutie please eat your broccoli, you have to be a dugma for Mendl."`,
  },
  DVEYKUS: {
    url: 'https://jel.jewish-languages.org/words/141',
    definition: `Spiritual ecstasy; lit. "cleaving." "The Chassin sings with dveykus".`,
  },
  FRESS: {
    url: 'https://jel.jewish-languages.org/words/170',
    definition: `To gobble up food; to gorge oneself. "You should have seen how we fressed at Breaking of the Fast."`,
  },
  FRESSER: {
    url: 'https://jel.jewish-languages.org/words/171',
    definition: `A heavy eater. "Only a fresser can finish that Reuben sandwich".  `,
  },
  GABAI: {
    url: 'https://jel.jewish-languages.org/words/176',
    definition: `The person who calls Jews to say blessings over the Torah reading and follows along with the Torah reading to correct any mistakes. "Mickey is gabbai, he'll be giving out the honours". `,
  },
  GONIF: {
    url: 'https://jel.jewish-languages.org/words/180',
    definition: `Thief. "The guy he was in business with was such a gonif. Now they're filing for bankruptcy". `,
  },
  GEHENOM: {
    url: 'https://jel.jewish-languages.org/words/183',
    definition: `Temporary hell. "Gehenom lasts for 11-12 months after someone passes away."`,
  },
  GOLEM: {
    url: 'https://jel.jewish-languages.org/words/197',
    definition: `A creature of Jewish medieval folklore, a golem is a figure made into the form of a human and given life. See The Golem (1920) movie https://www.imdb.com/title/tt0011237/ `,
  },
  GOLUS: {
    url: 'https://jel.jewish-languages.org/words/198',
    definition: `Literally "exile"; outside of the Land of Israel; away from a vibrant Jewish community; the time period since the destruction of the Second Temple at Jerusalem. `,
  },
  HAFSAKA: {
    url: 'https://jel.jewish-languages.org/words/209',
    definition: `Break or interruption. "Rivky, why are you late coming back from Hafsaka."`,
  },
  HAKAFAH: {
    url: 'https://jel.jewish-languages.org/words/213',
    definition: `Parade, processional with the Torah on Simchas Torah.`,
  },
  HALACHA: {
    url: 'https://jel.jewish-languages.org/words/215',
    definition: `Jewish law. "According to halachah, one can't eat chicken and milk together."`,
  },
  HASID: {
    url: 'https://jel.jewish-languages.org/words/222',
    definition: `A disciple of a rabbi. "Shmuel is Rabbi Solomons hasid"`,
  },
  HAZARAI: {
    url: 'https://jel.jewish-languages.org/words/225',
    definition: `Non-kosher food. "You can't eat that hazarai, Rivka"`,
  },
  HETER: {
    url: 'https://jel.jewish-languages.org/words/228',
    definition: `Rabbinic allowance. "You can't have a Yom Kipppur heter just cause you're hungry!"`,
  },
  HEIMISH: {
    url: 'https://jel.jewish-languages.org/words/229',
    definition: `Homey, cozy. "I love it already, your place is so heimish"`,
  },
  KASHRUT: {
    url: 'https://jel.jewish-languages.org/words/250',
    definition: `The body of Jewish dietary laws and regulations that specify what and how a Jew who keeps kosher can eat. "You've been telling porkies, that isn't kashrut"`,
  },
  KAVANAH: {
    url: 'https://jel.jewish-languages.org/words/251',
    definition: `Enthusiasm, especially during prayer. "You're never too old for kavanah, Ruth"`,
  },
  KEPPY: {
    url: 'https://jel.jewish-languages.org/words/255',
    definition: `Head, little head. "Keep your keppy held high"`,
  },
  KETUBAH: {
    url: 'https://jel.jewish-languages.org/words/256',
    definition: `The Jewish marriage contract, traditionally written in aramaic. "Me and grandpa had a beautiful ketubah"`,
  },
  CHATUNA: {
    url: 'https://jel.jewish-languages.org/words/257',
    definition: `Wedding. "Aba and I have our chatuna next week"`,
  },
  KIBBITZ: {
    url: 'https://jel.jewish-languages.org/words/258',
    definition: `To socialise aimlessly. "Rosie and Olga are always kibbitzing at shabbos "`,
  },
  KIBBUTZ: {
    url: 'https://jel.jewish-languages.org/words/259',
    definition: `israeli agricultural) collective. "I learned hebrew at a kibbutz"`,
  },
  KIDDUSH: {
    url: 'https://jel.jewish-languages.org/words/260',
    definition: `Blessing recited over wine. "I had to learn the kiddush by heart for my Barmi - it's burned in my memory." `,
  },
  KIRUV: {
    url: 'https://jel.jewish-languages.org/words/266',
    definition: `Outreach work meant to encourage non-religious Jews to become religious. "I'm doing kiruv at Rabbi Shmuel's house"`,
  },
  KISHKAS: {
    url: 'https://jel.jewish-languages.org/words/268',
    definition: `Intestines, gut. "That gefilte fish isn't sitting well in my kishkas"`,
  },
  KITNIOT: {
    url: 'https://jel.jewish-languages.org/words/270',
    definition: `Literally, 'legumes'...certain foods that are restricted during Pesach in some Jewish communities. "Mum, what does corn have to do with wheat rising? David's family is allowed to eat kitniot, why can't we"`,
  },
  KLEZMER: {
    url: 'https://jel.jewish-languages.org/words/272',
    definition: `Eastern Eurpoean style of instrumental music. "Klezmer music is my jam. I rock to it SO hard"`,
  },
  KLUTZ: {
    url: 'https://jel.jewish-languages.org/words/274',
    definition: `Clumsy, slow witted. "Yakov is such a klutz, I don't trust him to pour the wine"`,
  },
  KNESSET: {
    url: 'https://jel.jewish-languages.org/words/275',
    definition: `Israelis parliament. "Bibi is the head of the knesset"`,
  },
  KOTEL: {
    url: 'https://jel.jewish-languages.org/words/280',
    definition: `The Western Wall. "There's a sermon at the Kotel today"`,
  },
  KUGEL: {
    url: 'https://jel.jewish-languages.org/words/285',
    definition: `Baked pudding or casserole made with eggs and various ingredients, including noodles, potatoes, or spinach. "Sara makes the best Kugel"`,
  },
  KUMZITS: {
    url: 'https://jel.jewish-languages.org/words/287',
    definition: `Sing-along, campfire gathering with singing. "Let's sing the dreidel song at the kumzits"`,
  },
  KVELL: {
    url: 'https://jel.jewish-languages.org/words/289',
    definition: `Feel or express pride. "I'm kvelling - my little Doovy mad eme so proud when we was on the Bimah"`,
  },
  LABRIUT: {
    url: 'https://jel.jewish-languages.org/words/291',
    definition: `Bless you (after sneezing). "Achoo! Labriut"`,
  },
  LATKE: {
    url: 'https://jel.jewish-languages.org/words/299',
    definition: `A potato pancake (traditionally eaten on Hannukah) "Debbie makes latkes that are to DIE for"`,
  },
  LECHAIM: {
    url: 'https://jel.jewish-languages.org/words/301',
    definition: `A traditonal toast "To life, to life. L'chaim"`,
  },
  LITVISH: {
    url: 'https://jel.jewish-languages.org/words/312',
    definition: `Lithuanian; associated with the rational, intellectual culture of Lithuanian Jewry; Yeshivish. "Benji much prefers his Litvish yeshiva"`,
  },
  KINUS: {
    url: 'https://jel.jewish-languages.org/words/2149',
    definition: `Gathering. "They had a kinus at the Kotel for Manachem yesterday"`,
  },
  MACHANE: {
    url: 'https://jel.jewish-languages.org/words/324',
    definition: `Summer Camp. "Sammy had the best time last week at Machane"`,
  },
  MACHZOR: {
    url: 'https://jel.jewish-languages.org/words/327',
    definition: `High Holiday prayer book  (used for Rosh Ha'shanah and Yom Kippur). "Everyone turn to page  670 in your machzor"`,
  },
  MADRICH: {
    url: 'https://jel.jewish-languages.org/words/328',
    definition: `Youth counsellor or supervisor. "Jakob gets so into the songs when he's a madrich"`,
  },
  MAMALEH: {
    url: 'https://jel.jewish-languages.org/words/333',
    definition: `Diminutive term for a young girl. "Shayna's my mamaleh."`,
  },
  MAVEN: {
    url: 'https://jel.jewish-languages.org/words/347',
    definition: `An expert or connoisseur. "She's a maven when it comes to eating challah"`,
  },
  MECHAYA: {
    url: 'https://jel.jewish-languages.org/words/351',
    definition: `Pleasure, enjoyment. "To see you dance is such a mechaya"`,
  },
  MENUCHA: {
    url: 'https://jel.jewish-languages.org/words/363',
    definition: `Rest or rest time. "Kids, it's my favourite time of your day… Menucha!"`,
  },
  MESHUGA: {
    url: 'https://jel.jewish-languages.org/words/367',
    definition: `Crazy. "Oh! That Miriam is meshuga!"`,
  },
  MEZUZAH: {
    url: 'https://jel.jewish-languages.org/words/371',
    definition: `A piece of parchment with the Shema written on it that is then placed in a case and mounted on doorways in keeping with the Biblical commandment mentioned in the Shema. "Don't forget to kiss the mezuzah"`,
  },
  MIDRASH: {
    url: 'https://jel.jewish-languages.org/words/374',
    definition: `Stories or explanations that interpret or elaborate on the Torah's text. "The midrash helps us understand the Torah"`,
  },
  MILAH: {
    url: 'https://jel.jewish-languages.org/words/377',
    definition: `Ritual circumcision. "You can't be a Jewish man without a milah"`,
  },
  MILCHIG: {
    url: 'https://jel.jewish-languages.org/words/378',
    definition: `Food in the "dairy" category, according to the Jewish dietary laws...also refers to the utensils and dishes used for cooking, eating, and serving milk products. "Pudding is milchig, so we can't eat it after we eat our meat"`,
  },
  MISHNAH: {
    url: 'https://jel.jewish-languages.org/words/383',
    definition: `n authoritative collection of exegetical material embodying the oral tradition of Jewish law and forming the first part of the Talmud. "A lot of rabinical sages argue in the Mishnah passages"`,
  },
  MITZVAH: {
    url: 'https://jel.jewish-languages.org/words/386',
    definition: `Good deed. "Helping Sara cross the street? What a mitzvah!"`,
  },
  MUKTZAH: {
    url: 'https://jel.jewish-languages.org/words/390',
    definition: `An object that it is forbidden to handle on the Sabbath and on certain holidays. "Drop that phone, Rachel, it's muktzah!"`,
  },
  MUSAF: {
    url: 'https://jel.jewish-languages.org/words/391',
    definition: `The additional service added to the morning services on the Sabbath and holidays. "Avram is leading shacharis, and Jesse is leading musaf"`,
  },
  MUSAR: {
    url: 'https://jel.jewish-languages.org/words/392',
    definition: `Reproof; system of ethics; discipline. "Don't give me mussar!"`,
  },
  NEDER: {
    url: 'https://jel.jewish-languages.org/words/398',
    definition: `A vow. "A neder is treated very seriously in Jewish Law"`,
  },
  NEROT: {
    url: 'https://jel.jewish-languages.org/words/403',
    definition: `Candles. "Blessing nerot is a mitzvah for women"`,
  },
  NESHAMA: {
    url: 'https://jel.jewish-languages.org/words/405',
    definition: `Soul. "Mendy's neshama is pure. He is so kind and thoughtful."`,
  },
  NIGUN: {
    url: 'https://jel.jewish-languages.org/words/410',
    definition: `A wordless melody, a tune. "My favourite nigun is the one for mah nishtana"`,
  },
  NISAYON: {
    url: 'https://jel.jewish-languages.org/words/411',
    definition: `Trial and tribulation, a test of faith. "Life is filled with nisayon. You don't know why bad things happen but you accept Hashem's plan. "`,
  },
  PARNASA: {
    url: 'https://jel.jewish-languages.org/words/426',
    definition: `Livelihood, income. "Working at the shop is my parnasa"`,
  },
  PARVE: {
    url: 'https://jel.jewish-languages.org/words/427',
    definition: `Containing or contaminated with neither milk nor meat. "I bought a parve dessert for our meat meal"`,
  },
  PATSH: {
    url: 'https://jel.jewish-languages.org/words/429',
    definition: `To give a light slap. "You deserve a patsh for that one, Lottie"`,
  },
  PLOTZ: {
    url: 'https://jel.jewish-languages.org/words/438',
    definition: `To split, to burst, to explode. "The news was so wonderful I almost plotzed when I heard it!"`,
  },
  PUNIM: {
    url: 'https://jel.jewish-languages.org/words/446',
    definition: `Face. "That punim! I'm swooning over it, Deb"`,
  },
  PUPIK: {
    url: 'https://jel.jewish-languages.org/words/447',
    definition: `Navel, belly button. "Imma tickle your pupik"`,
  },
  REBBE: {
    url: 'https://jel.jewish-languages.org/words/453',
    definition: `The spiritual leader of a Hasidic sect. "Satmar now has two Rebbes"`,
  },
  RUACH: {
    url: 'https://jel.jewish-languages.org/words/463',
    definition: `Spirit, "spiritedness, morale." "The ruach is my favourite part of school camp"`,
  },
  SABRA: {
    url: 'https://jel.jewish-languages.org/words/468',
    definition: `A prickly pear. "He's israeli, he can be a bit of a sabra"`,
  },
  SAVTA: {
    url: 'https://jel.jewish-languages.org/words/471',
    definition: `Grandmother. "Savta is calling, I think it's a butt dial"`,
  },
  SCHNORR: {
    url: 'https://jel.jewish-languages.org/words/481',
    definition: `To get something for free, to mooch. "Get your sister an ice-cream too, don't be a schnorrer"`,
  },
  SCHVITZ: {
    url: 'https://jel.jewish-languages.org/words/483',
    definition: `Sweat. "I'm already schvitzing from the walk up, now I have to walk DOWN"`,
  },
  SEFER: {
    url: 'https://jel.jewish-languages.org/words/484',
    definition: `Hebrew or Aramaic book. "What sefer are you learning"`,
  },
  SEFORIM: {
    url: 'https://jel.jewish-languages.org/words/487',
    definition: `Religious books, especially those written in Hebrew or Aramaic. "We are adding new seforim to our site all the time, we'll have a book for everyone soon!"`,
  },
  SHABBAT: {
    url: 'https://jel.jewish-languages.org/words/488',
    definition: `The Jewish Sabbath; Saturday. "Rivky is making her famous kugel for Shabbat this week"`,
  },
  SHAMASH: {
    url: 'https://jel.jewish-languages.org/words/497',
    definition: `The caretaker of a synagogue. "The shamash needs to order more siddur"`,
  },
  SHEITEL: {
    url: 'https://jel.jewish-languages.org/words/507',
    definition: `Wig worn by some observant Jewish women after marriage. "She looks like a queen in her sheitel"`,
  },
  SHICKER: {
    url: 'https://jel.jewish-languages.org/words/514',
    definition: `Tipsy/drunk. "Watch that Shabbas wine, Bubbe got a little shicker last Shabbos"`,
  },
  SHIUR: {
    url: 'https://jel.jewish-languages.org/words/518',
    definition: `A lesson, lecture, or discourse on biblical or rabbinic texts or other Jewish concepts. "Did you sleep through the Rabbi's shiur again?"`,
  },
  SHLUFFY: {
    url: 'https://jel.jewish-languages.org/words/523',
    definition: `To go) to sleep. "Dovvy, it's time to shluff, don't forget to kiss Bubbe goodnight"`,
  },
  SHOAH: {
    url: 'https://jel.jewish-languages.org/words/528',
    definition: `Eastern European Holocaust. "My grandmother is a Shoah survivor"`,
  },
  SHOMER: {
    url: 'https://jel.jewish-languages.org/words/531',
    definition: `Observant, observing the laws of kosher, shabbat, or negiya (not touching members of the opposite gender outside of family). "I can't shake my teacher's hand, I'm shomer"`,
  },
  SPIEL: {
    url: 'https://jel.jewish-languages.org/words/533',
    definition: `A long, involved story or tale. "Rottie - what a spiel - cut to the chase"`,
  },
  SHTUS: {
    url: 'https://jel.jewish-languages.org/words/540',
    definition: `Nonsense. "Don't watch 8 Crazy Nights, its utter shtus"`,
  },
  SHUCKLE: {
    url: 'https://jel.jewish-languages.org/words/541',
    definition: `Literally "to shake" to sway back and forth during prayer. "Look at Dovi, he really shuckles, it makes me dizzy"`,
  },
  SIYUM: {
    url: 'https://jel.jewish-languages.org/words/548',
    definition: `Literally, "completion" traditionally means completing studying of a tractate of talmud, often with a ceremony and celebration following, but can be used for the completion of another course of study. "We're making a siyum on Brachos today."`,
  },
  SMICHAH: {
    url: 'https://jel.jewish-languages.org/words/551',
    definition: `Rabinical ordination. "After countless sleepless nights of study, we're finally at the smichah"`,
  },
  TACHLIS: {
    url: 'https://jel.jewish-languages.org/words/556',
    definition: `Purpose, aim. "On Purim, it is my tachlis to not stay sober"`,
  },
  TEKES: {
    url: 'https://jel.jewish-languages.org/words/571',
    definition: `Ceremony, usually surrounding something identified as Israeli or Jewish. "Yom Hatzmaut has my favourite tekes"`,
  },
  TISCH: {
    url: 'https://jel.jewish-languages.org/words/577',
    definition: `A festive friday night gathering, often involving alcohol and singing. "After shul, we went to the Rebbe's tisch"`,
  },
  TORAH: {
    url: 'https://jel.jewish-languages.org/words/579',
    definition: `A scroll with the text from The Five Books of Moses on it. "The Rabbi's sermon on this week's Torah portion was very insightful"`,
  },
  TREIF: {
    url: 'https://jel.jewish-languages.org/words/582',
    definition: `Food prohibited by the Jewish dietary laws. "Not only is Hawaiian pizza illegal cause of the pineapple, it's also treif"`,
  },
  TZRIF: {
    url: 'https://jel.jewish-languages.org/words/596',
    definition: `Cabin/bunk. "Come over to our tzrif after dinner, we're watching a movie"`,
  },
  VAYIKRA: {
    url: 'https://jel.jewish-languages.org/words/602',
    definition: `The book of Leviticus. "Vayikra mostly gives laws/commandments"`,
  },
  YELADIM: {
    url: 'https://jel.jewish-languages.org/words/611',
    definition: `Children. "Settle yeladim, it's almost lunchtime"`,
  },
  YENTA: {
    url: 'https://jel.jewish-languages.org/words/613',
    definition: `Informal matchmaker. "Rivky keeps trying to play yenta and set me up with her son"`,
  },
  YESHIVA: {
    url: 'https://jel.jewish-languages.org/words/615',
    definition: `An institution that focuses on teaching Talmud. "The yeshiva we stayed at in Eilat had the nicest cooking"`,
  },
  YIDDISH: {
    url: 'https://jel.jewish-languages.org/words/619',
    definition: `Traditional language of Ashkenazic  Jewry. "My grandma is the only person I know who still speaks fluent yiddish" `,
  },
  ZATAR: {
    url: 'https://jel.jewish-languages.org/words/633',
    definition: `An Israeli spice mixture made from oregano, basil, thyme, and savory, sesame seeds, and dried sumac. "I love  humus with zatar"`,
  },
  PEULA: {
    url: 'https://jel.jewish-languages.org/words/1281',
    definition: `Activity. "Swimming is my favourtie peula at camp"`,
  },
  KAHAL: {
    url: 'https://jel.jewish-languages.org/words/1287',
    definition: `Synagogue, congregation. "On Yom Kippur, the only thing to do is go to kahal"`,
  },
  CHASHUV: {
    url: 'https://jel.jewish-languages.org/words/1290',
    definition: `Important, of high status. "Rabbi Lord Jonathan Sacks was a man of chashuv"`,
  },
  MAROR: {
    url: 'https://jel.jewish-languages.org/words/1314',
    definition: `Bitter herbs placed on the Seder plate on Passover. "Alright, time to make the sandwich with maror and charoset"`,
  },
  LULAV: {
    url: 'https://jel.jewish-languages.org/words/1315',
    definition: `Palm branch used ritually on Sukkot. "Jonathan really gets into it when he's shaking the lulav"`,
  },
  ETROG: {
    url: 'https://jel.jewish-languages.org/words/1316',
    definition: `Citron generally used ritually on Sukkot. "Don't cut the etrog! It's not a lemon!"`,
  },
  NIKAYON: {
    url: 'https://jel.jewish-languages.org/words/1317',
    definition: `Time set aside for cleaning/tidying up (at camp). "After dinner, it'll be nikayon time!"`,
  },
  RIKUD: {
    url: 'https://jel.jewish-languages.org/words/1318',
    definition: `Dancing, especially Israeli dancing. "Liat wants rikud at her wedding"`,
  },
  KADDISH: {
    url: 'https://jel.jewish-languages.org/words/1321',
    definition: `Prayer of mourning. "Is there anyone in the congregation who has named for Kaddish?"`,
  },
  GEDOLIM: {
    url: 'https://jel.jewish-languages.org/words/1322',
    definition: `Feces, excrement. "Don't go in there, I just took a stinker of a gedolim"`,
  },
  ASSUR: {
    url: 'https://jel.jewish-languages.org/words/1331',
    definition: `Forbidden. "Eating bread on Pesach? Assur!"`,
  },
  KETER: {
    url: 'https://jel.jewish-languages.org/words/1336',
    definition: `Crown, especially the crown decorating a Torah scroll. "The barmitzvah boy almost dropped the keter"`,
  },
  YEKKE: {
    url: 'https://jel.jewish-languages.org/words/1340',
    definition: `A Jew of German heritage. "He runs the bank? What a Yekke!"`,
  },
  CHIDUSH: {
    url: 'https://jel.jewish-languages.org/words/1345',
    definition: `Innovative idea, an insight, discovery, novelty. "Self driving cars? What a chidush!"`,
  },
  BAYIT: {
    url: 'https://jel.jewish-languages.org/words/1360',
    definition: `Ancient temple. "We wait for the G-d to bring us the third bayit"`,
  },
  BICHLAL: {
    url: 'https://jel.jewish-languages.org/words/1365',
    definition: `In any way, entirely, in general, altogether. "He learns all day, and he's bichlal not interested in sports"`,
  },
  BUBBALE: {
    url: 'https://jel.jewish-languages.org/words/1373',
    definition: `Sweetie, darling (term of endearment). "Have fun at school my little bubbale"`,
  },
  CHACHAM: {
    url: 'https://jel.jewish-languages.org/words/1375',
    definition: `Wise, learned person; genius. "Chacham! I've never seen with such understanding of Talmud"`,
  },
  HAVURAH: {
    url: 'https://jel.jewish-languages.org/words/1376',
    definition: `A group of friends, frequently a social or religious groio, either within a synagogue or apart from one. "I meet with my havurah weekly at Moishe's house"`,
  },
  SUGYA: {
    url: 'https://jel.jewish-languages.org/words/1409',
    definition: `Passage from the Talmud.`,
  },
  SHIRA: {
    url: 'https://jel.jewish-languages.org/words/1410',
    definition: `Singing session. "Let's all get together tomorrow morning and have a shira"`,
  },
  SHTUP: {
    url: 'https://jel.jewish-languages.org/words/1412',
    definition: `To have sexual intercourse. "I heard Brian and Claire are shtupping."`,
  },
  MOHEL: {
    url: 'https://jel.jewish-languages.org/words/1416',
    definition: `A person trained to perform Jewish ritual circumcision. "In keeping with tradtion, we'll need a mohel to perform the bris. `,
  },
  KELEV: {
    url: 'https://jel.jewish-languages.org/words/1421',
    definition: `Dog. "Someone clean up the kelev's mess!"`,
  },
  KNAIDEL: {
    url: 'https://jel.jewish-languages.org/words/1422',
    definition: `Matzah ball. "You have to get me Lottie's recipe for those knaidel"`,
  },
  POSEK: {
    url: 'https://jel.jewish-languages.org/words/1446',
    definition: `A rabbi/scholar who makes halachic decisions/rulings. "Samuel is a great posek"`,
  },
  RENEWAL: {
    url: 'https://jel.jewish-languages.org/words/1455',
    definition: `Practices and beliefs in keeping with the philosophy of the renewal movement. "Jewish renewal is a worldwide, trans-denominational movement grounded in Judaism’s prophetic and mystical traditions."`,
  },
  GLATT: {
    url: 'https://jel.jewish-languages.org/words/1464',
    definition: `Super-kosher (not only food), or acceptable to the Haredi community. "We're having our seder at the Rebbe's house, guaranteed glatt kosher"`,
  },
  KRIAH: {
    url: 'https://jel.jewish-languages.org/words/1473',
    definition: `Reading, especially Torah. "Becoming for a barmitzvah requires kriah"`,
  },
  HAMSA: {
    url: 'https://jel.jewish-languages.org/words/1477',
    definition: `A hand-shaped amulet conferring good luck or protecting. "I sense the evil eye, get the hamsa!"`,
  },
  MACHMIR: {
    url: 'https://jel.jewish-languages.org/words/1523',
    definition: `Meticulous. "Rivky is always machmir - always with the matching shoes and bag."`,
  },
  LOKSHEN: {
    url: 'https://jel.jewish-languages.org/words/1530',
    definition: `Noodles. "Stop slurping your loshken"`,
  },
  NEBBISH: {
    url: 'https://jel.jewish-languages.org/words/1532',
    definition: `Nerdy, clumsy, awkward person. "Mark is such a nebbish"`,
  },
  KAPAROT: {
    url: 'https://jel.jewish-languages.org/words/1533',
    definition: `Ritual performed between Rosh Hashanah and Yom Kippur to remove sins carries out by swinging a chicken over ones head. "Rabbi Shmuel got a little too into the kaparot this year"`,
  },
  PARASHA: {
    url: 'https://jel.jewish-languages.org/words/1541',
    definition: `Selection of the Pentateuch read publicly on the Sabbath. "What parsha is he doing for his bar mitzvah"`,
  },
  PROST: {
    url: 'https://jel.jewish-languages.org/words/1542',
    definition: `Coarse, base, not class, vulga, common. "He didn't have to go into that much detail about his wedding night, he's so prost"`,
  },
  SEDER: {
    url: 'https://jel.jewish-languages.org/words/1547',
    definition: `A ceremony involving recitations, singing, food, and drink typically associated with Passover, but also with Tu B'shvat and Rosh Hashanah. "We're going to Rabbi Shmuel's house this year for the Pesach seder."`,
  },
  KEDUSHA: {
    url: 'https://jel.jewish-languages.org/words/1548',
    definition: `Holiness, sanctity. "Great Rabbis have an aura of kedusha about them"`,
  },
  PAYOS: {
    url: 'https://jel.jewish-languages.org/words/1558',
    definition: `Sidelocks, sidecurls, or earlocks, common among Haredi males. "It's customary for Chasidic Jews to allow their peyos to grow out"`,
  },
  ACHSHAV: {
    url: 'https://jel.jewish-languages.org/words/1571',
    definition: `Now, immediately. "We forgot to buy Challah. Achshav! To Grandma Moses!"`,
  },
  KNISH: {
    url: 'https://jel.jewish-languages.org/words/1580',
    definition: `Eastern European dumplings with thin dough filled with potato, kasha, onion, meat, and/or other ingredients. "You HAVE to try my Bubbe's knish"`,
  },
  MENORAH: {
    url: 'https://jel.jewish-languages.org/words/1587',
    definition: `Candelabrum, espescially for Chanukah. "Chanukah is tomorrow! Quick, get the menorah!"`,
  },
  PURIM: {
    url: 'https://jel.jewish-languages.org/words/1592',
    definition: `A joyous holiday that takes place in February or March on the 14th of Adar, retelling the story of the Book of Esther. "The shul is always full of energy on Purim"`,
  },
  TEIKU: {
    url: 'https://jel.jewish-languages.org/words/1598',
    definition: `An unanswered question. "Some of the best questions on religion are teiku"`,
  },
  TZITZIT: {
    url: 'https://jel.jewish-languages.org/words/1600',
    definition: `The fringes on a tallit or a tallit katan. "Remember boys, tuck your tzitzit in when you're playing rugby"`,
  },
  GIYUR: {
    url: 'https://jel.jewish-languages.org/words/1607',
    definition: `Conversion ceremony. "Brian has his giyur this weekend"`,
  },
  SOFER: {
    url: 'https://jel.jewish-languages.org/words/1609',
    definition: `A scribe trained in transcribing the Torah, mezuzah, tefillin scriptures and other religious texts on parchment. "Someone call the sofer, our Torah is looking a little worse for wear"`,
  },
  HALEVAI: {
    url: 'https://jel.jewish-languages.org/words/1615',
    definition: `I wish! "We'll find you a good Jewish man, Rivky" "Halevai"`,
  },
  SHPRITZ: {
    url: 'https://jel.jewish-languages.org/words/1628',
    definition: `Spray, squirt. "Every morning, he's out there shpritzing the lillies"`,
  },
  SHVER: {
    url: 'https://jel.jewish-languages.org/words/1633',
    definition: `Difficult, onerous, complex. "Stop being shver, Lottie!"`,
  },
  SCHMO: {
    url: 'https://jel.jewish-languages.org/words/1637',
    definition: `A food, a jerk. "He thought coronavirus was fake? What a schmo!"`,
  },
  SHEMA: {
    url: 'https://jel.jewish-languages.org/words/1639',
    definition: `Prayer recited in most services as well as at bedtime, and when death is imminent; one of the most important and well-known prayers in Judaism. "Turn to page 94 for the Shema"`,
  },
  GENUG: {
    url: 'https://jel.jewish-languages.org/words/1640',
    definition: `Enough. "Boychick, genug! Leave the kelev alone!"`,
  },
  SHATNEZ: {
    url: 'https://jel.jewish-languages.org/words/1644',
    definition: `Mixture of wool and linen in a garment, which is prohibited by Jewish law. "Did the shatnez checker give the ok on your suit?"`,
  },
  MAZIK: {
    url: 'https://jel.jewish-languages.org/words/1653',
    definition: `A mischevious person, often a child. "Channah's boy is adorable, but he's always up to something. He's such a little mazik"`,
  },
  KASHA: {
    url: 'https://jel.jewish-languages.org/words/1664',
    definition: `Porridge. "Do you want kasha or rice at brunch?"`,
  },
  PASUK: {
    url: 'https://jel.jewish-languages.org/words/1666',
    definition: `A verse, usually from the Torah. "Rabbi Sacks has an interesting commentary on our pasuk"`,
  },
  MISHKAN: {
    url: 'https://jel.jewish-languages.org/words/1681',
    definition: `The tabernacle or temporary sanctuary in which the Divine Presence dwelled during the Jews' journeys through the desert. "The Jews constructed the Mishkan"`,
  },
  FALAFEL: {
    url: 'https://jel.jewish-languages.org/words/1686',
    definition: `A deep-fried chick pea ball . "The falafel at the cart down the street is the best!"`,
  },
  SIDRA: {
    url: 'https://jel.jewish-languages.org/words/1698',
    definition: `Weekly Torah portion. "The opening Passuk in this week’s sidra, in describing Noach, tells us נח איש צדיק תמים היה בדורותיו –Noach was a righteous man, perfect in his generations"`,
  },
  BITUL: {
    url: 'https://jel.jewish-languages.org/words/2174',
    definition: `Contempt, disparagement. "I have real bitul for my neighbours, they're too loud!"`,
  },
  DAYAN: {
    url: 'https://jel.jewish-languages.org/words/1778',
    definition: `A judge who sits and adjudicates cases involving religious practices or spiritual matters. "What I am trying to say is that the dayan receives Divine assistance when he is weighing the case before him, so that he will pasken correctly"`,
  },
  SIKKUM: {
    url: 'https://jel.jewish-languages.org/words/1779',
    definition: `A summary. "In Sikkum, social action and Judaism go hand-in-hand"`,
  },
  HAKDAMA: {
    url: 'https://jel.jewish-languages.org/words/1780',
    definition: `Introduction or preface (to a book). "The hakdama to Fiddler on the Roof is quite a read!"`,
  },
  MAZEL: {
    url: 'https://jel.jewish-languages.org/words/1785',
    definition: `Congratulations. "You tied the knot? Mazel!"`,
  },
  SHEKET: {
    url: 'https://jel.jewish-languages.org/words/1819',
    definition: `Quiet. "Yeladim! Sheket bevakasha!!"`,
  },
  INYAN: {
    url: 'https://jel.jewish-languages.org/words/1829',
    definition: `Significance within Jewish law. "What's the inyan to learning Torah?"`,
  },
  MUTAR: {
    url: 'https://jel.jewish-languages.org/words/1831',
    definition: `Permitted for a Jew to do. "The Rebbe said it is mutar to eat rice on Pesach if you're Sefardi"`,
  },
  COHEN: {
    url: 'https://jel.jewish-languages.org/words/1834',
    definition: `A male descendent of the family of Aaron, of the tribe of Levi. "He's a Cohen, so does that mean he can't go into the cemetary?"`,
  },
  CHINUCH: {
    url: 'https://jel.jewish-languages.org/words/1848',
    definition: `Jewish education. "We have the kids daven every morning before school, for the sake of chinuch"`,
  },
  PLONI: {
    url: 'https://jel.jewish-languages.org/words/1859',
    definition: `An anonymous placeholder name, like "John Doe". "Our speaker today has decided to remain anonymous, he's a real ploni"`,
  },
  BANOT: {
    url: 'https://jel.jewish-languages.org/words/1865',
    definition: `Girls. "The right side of the synagogue is reserved for the banot"`,
  },
  TIYUL: {
    url: 'https://jel.jewish-languages.org/words/1876',
    definition: `A walk, journey, hike, field trip, or overnight. "A tiyul to Mt. Sinai is one of the most important parts of any Israel trip"`,
  },
  CHOFESH: {
    url: 'https://jel.jewish-languages.org/words/1882',
    definition: `Free time. "We're watching the Prince of Egypt during chofesh"`,
  },
  GLIDA: {
    url: 'https://jel.jewish-languages.org/words/1884',
    definition: `Ice-cream. "My favourite glida is chocolate"`,
  },
  DEGEL: {
    url: 'https://jel.jewish-languages.org/words/1898',
    definition: `Flag, often refers to "morning degel" the flag-raising ceremony at many camps. "Our morning degel includes the Israeli, Australian and Aboriginal flags"`,
  },
  TEATRON: {
    url: 'https://jel.jewish-languages.org/words/1913',
    definition: `Theater. "The crowd in the teatron erupted during the finale"`,
  },
  AGGADAH: {
    url: 'https://jel.jewish-languages.org/words/1937',
    definition: `Parts of the Talmud and Midrash that do not deal with law, including stories, fables, and sayings. "We studied an interesting aggadah about matzah"`,
  },
  BIALY: {
    url: 'https://jel.jewish-languages.org/words/1943',
    definition: `A flat breakfast roll, shaped like a round wading pool. "I had eggs and bialy for breakfast"`,
  },
  MELACHA: {
    url: 'https://jel.jewish-languages.org/words/1946',
    definition: `Art or skill: Trade. "Whenever my Bubbe gets a new gadget, she always asks if it'll require melacha"`,
  },
  AMORA: {
    url: 'https://jel.jewish-languages.org/words/1950',
    definition: `Talmudic sage whose teachings comprise the Gemara. "The amoraim were active between approximately 220 C.E. and 360 or 370 in the Land of Israel"`,
  },
  ARAMAIC: {
    url: 'https://jel.jewish-languages.org/words/1954',
    definition: `Judeo-Aramaic, a northern Semitic language related to Hebrew. "Many of our prayers are done in Aramaic"`,
  },
  BUREKAS: {
    url: 'https://jel.jewish-languages.org/words/1975',
    definition: `A Turkish pastry filled with potatoes, other vegetables, and sometimes cheese. "Jessie makes bourekas that are to DIE for"`,
  },
  STRUDEL: {
    url: 'https://jel.jewish-languages.org/words/2005',
    definition: `A pastry made by rolling up a think sheet of dough with a fruit or cheese filling and baking it. "I could KILL for one of Bubbe's strudels right now"`,
  },
  KEPPELE: {
    url: 'https://jel.jewish-languages.org/words/2012',
    definition: `Head (smaller or cuter, oftne used in child-directed speech). "Put your keppele down and get some rest!"`,
  },
  TZIMMES: {
    url: 'https://jel.jewish-languages.org/words/2013',
    definition: `A sweet, baked dish of vegetables or fruit. Carrot tzimmes, a popular version, includes carrots, sweet potatoes, dried apricots, raisins, sugar, and cinnamon. "My Bubbe's tzimmes usually has honey, carrots and prunes, mmmm the BEST!"`,
  },
  PESADIK: {
    url: 'https://jel.jewish-languages.org/words/2015',
    definition: `Kosher for Passover. "The saddest thing about Passover is that Pizza isn't pesadik"`,
  },
  GEZERAH: {
    url: 'https://jel.jewish-languages.org/words/2026',
    definition: `In Jewish law, a prohibition enacted by the Sages as a measure to protect religious observance under changed conditions. "A gezerah was instituted when occasion demanded"`,
  },
  KEZAYIT: {
    url: 'https://jel.jewish-languages.org/words/2044',
    definition: `A halakhic measure for the minimum amount that may not be eaten of a prohibited substance or that must be eaten to fulfill a commandment. "Rambam specified that a 'grogeret' (dried fig) was one-third of a beytza, making this the maximum size for a kezayit"`,
  },
  SAFEK: {
    url: 'https://jel.jewish-languages.org/words/2053',
    definition: `A doubt or doubtful case. "The Rabbi refuses to eat at that restaurant because of a safek in its kashuring"`,
  },
  SHTAR: {
    url: 'https://jel.jewish-languages.org/words/2055',
    definition: `Any legal document or writ, such as a bill of sale or a promissory note. "Your honour, I present this shtar as evidence against the accused"`,
  },
  ARAVA: {
    url: 'https://jel.jewish-languages.org/words/2068',
    definition: `The dry lowland area of the eastern Negev, often called the Aravah Valley. "In the Central Arava, located in southern Israel along the Jordanian border and spanning between the Dead Sea and Eilat, one would be hard-pressed to find the vast pine and oak forests commonly seen across Central and Northern Israel"`,
  },
  BESAMIM: {
    url: 'https://jel.jewish-languages.org/words/2087',
    definition: `Spices. "Doovy, bring me the besamin box."`,
  },
  SHOCHET: {
    url: 'https://jel.jewish-languages.org/words/2114',
    definition: `Ritual slaughter in the system of kashrut. "We eat meat in our house, our father's a shoychet"`,
  },
  TAHOR: {
    url: 'https://jel.jewish-languages.org/words/2116',
    definition: `Ritually pure, clean. "Make sure to wash the utensils throughly or they'll stop being tahor"`,
  },
  MADREGA: {
    url: 'https://jel.jewish-languages.org/words/2123',
    definition: `Level, especially, spiritual level. "You keep Kosher, so you're on a different madrega than myself"`,
  },
  ACHLA: {
    url: 'https://jel.jewish-languages.org/words/2125',
    definition: `Awesome, excellent, great, the best. "You cleaned the house? Achla!"`,
  },
  CHALAVI: {
    url: 'https://jel.jewish-languages.org/words/2204',
    definition: `Describes dairy foods as well as utensils and dishes used to cook, serve, and eat dairy products. "I can't use this fork for my steak, it's chalavi"`,
  },
  SHELITA: {
    url: 'https://jel.jewish-languages.org/words/2210',
    definition: `Honorific said or written after stating the name of an important person, mostly commonly an Orthodox rabbi. "I heard the rabbi turned 87 last week?" "Yes! Shelita"`,
  },
  KITAH: {
    url: 'https://jel.jewish-languages.org/words/2239',
    definition: `Class, grade (in school). "The kids I taught in kitah were very restless today"`,
  },
  HAGBA: {
    url: 'https://jel.jewish-languages.org/words/2241',
    definition: `The ceremonial raising of a Sefer Torah for all the congregation to see. "The barmitzvah boy definitley struggled with hagba"`,
  },
  GELILAH: {
    url: 'https://jel.jewish-languages.org/words/2242',
    definition: `Binding and bedecking of the Torah scroll. "Could the barmitzvah boy please come up to do geliliah"`,
  },
  ARVIT: {
    url: 'https://jel.jewish-languages.org/words/2250',
    definition: `The Jewish evening prayer service. "We're saying arvit together tonight"`,
  },
  SHAVUOT: {
    url: 'https://jel.jewish-languages.org/words/2280',
    definition: `A Jewish holiday celebrating the anniversary of Moses receiving the Ten Commandments at Mount Sinai and the first harvest of spring. "Shavuot is the only time of year that I can fill myself with cheesecake without guilt"`,
  },
  HASIDIC: {
    url: 'https://jel.jewish-languages.org/words/2284',
    definition: `Of or relating to Hasidic Judaism or Hasidic Jews. "I respect the dedication to being Hasidic"`,
  },
  TEHILIM: {
    url: 'https://jel.jewish-languages.org/words/2289',
    definition: `The Book of Psalms. "Please say tehilim for my father, he is in hospital"`,
  },
  BADCHEN: {
    url: 'https://jel.jewish-languages.org/words/2300',
    definition: `A merry maker as at a Jewish wedding or other Jewish celebration. "The badchen at my batmitzvah had the whole crowd falling out of their seats"`,
  },
  TEVET: {
    url: 'https://jel.jewish-languages.org/words/2321',
    definition: `The fourth month of the Jewish calendar. "While Tevet is one if the darker months of the year, Chanukah is the light of the month"`,
  },
  NISAN: {
    url: 'https://jel.jewish-languages.org/words/2323',
    definition: `The seventh month of the Jewsh calendar. "During the month of Nisan, it is a mitzvah, which can be fulfilled once a year, to recite the berachah upon seeing a fruit tree in bloom"`,
  },
  SIVAN: {
    url: 'https://jel.jewish-languages.org/words/2325',
    definition: `The ninth month of the Jewish calendar. "In Israel, Sivan is harvest season"`,
  },
  BADEKEN: {
    url: 'https://jel.jewish-languages.org/words/2346',
    definition: `A brief ritual performed among Ashkenazim just before the wedding ceremony, in which a veil is placed or lowered over the bride's face while reciting the blessing made on Rebecca. "The badeken ceremony commences after the pre-chupah reception"`,
  },
  CHILONI: {
    url: 'https://jel.jewish-languages.org/words/2348',
    definition: `Of Jewish people or institutions) secular, not religious. "Tel Aviv, due to it being such a modernised part of Israel, has a lot of Jews who are chiloni"`,
  },
  ZIONISM: {
    url: 'https://jel.jewish-languages.org/words/2362',
    definition: `The belief that the Jewish people has the right to a Jewish state in the land of Israel. "Most palestinians generally oppose Zionism"`,
  },
  ZIONIST: {
    url: 'https://jel.jewish-languages.org/words/2363',
    definition: `A supporter of Zionism. "Zionists believe Jewish peopple deserve their own state in their ancestral homeland"`,
  },
  PEREK: {
    url: 'https://jel.jewish-languages.org/words/2370',
    definition: `A chapter of a Jewish text (usually a book of the Tanach). "Everyone please open your Tanach, perek 10, pasuk 3"`,
  },
  LAINING: {
    url: 'https://jel.jewish-languages.org/words/2388',
    definition: `The/a Torah reading. "My Rabbi really helped me with my laining"`,
  },
  KETUVIM: {
    url: 'https://jel.jewish-languages.org/words/2393',
    definition: `Third and last section of the Tanach. "Ultimately, each of the Ketuvim affirms a hard-won commitment to God and covenant"`,
  },
  HAZERET: {
    url: 'https://jel.jewish-languages.org/words/2399',
    definition: `A bitter vegetable, often romaine lettuce, sometimes placed on the seder plate. "I want maror hazeret!"`,
  },
  ZEROA: {
    url: 'https://jel.jewish-languages.org/words/2400',
    definition: `The roasted shank bone placed on the seder plate on Pesach. "At the end of the seder, my Bubbe lets me eat the zeroa"`,
  },
  ADERABA: {
    url: 'https://jel.jewish-languages.org/words/4',
    definition: `By all means, certainly. "You want to try hosting the seder this year? Aderaba, less cleanup for me!"`,
  },
  AMCHA: {
    url: 'https://jel.jewish-languages.org/words/19',
    definition: `The every-man, everyday people; the folk (as opposed to the elite). "My Pop was the voice of the amcha when he was younger"`,
  },
  AVADE: {
    url: 'https://jel.jewish-languages.org/words/27',
    definition: `Obviously! "Is Israel the Jewish homeland? Avade!"`,
  },
  BALABOS: {
    url: 'https://jel.jewish-languages.org/words/38',
    definition: `Male head of a household. "The Tv is broken? Let the balabos look at it before we call in a repaid"`,
  },
  GADOL: {
    url: 'https://jel.jewish-languages.org/words/177',
    definition: `Big. "Bubbe, when I will become gadol like you?"`,
  },
  GESHMAK: {
    url: 'https://jel.jewish-languages.org/words/188',
    definition: `Delicious, delightful. "Your grandchildren are absolutley geshmak, Rivky"`,
  },
  ICHSA: {
    url: 'https://jel.jewish-languages.org/words/232',
    definition: `An expression of disgust. "Ichsa! Who made this soup"`,
  },
  KEDAI: {
    url: 'https://jel.jewish-languages.org/words/252',
    definition: `Worth it. "It is definitley kadai to make a trip to Israel atleast once"`,
  },
  KIMAT: {
    url: 'https://jel.jewish-languages.org/words/263',
    definition: `Almost or nearly. "He is kimat barmitzvah"`,
  },
  MABUL: {
    url: 'https://jel.jewish-languages.org/words/323',
    definition: `Torrential rain. "What a mabul, I looked like a drowned rat!"`,
  },
  MESORAH: {
    url: 'https://jel.jewish-languages.org/words/368',
    definition: `Tradition. "Fiddler on the Roof is all about mesorah"`,
  },
  PATSHKE: {
    url: 'https://jel.jewish-languages.org/words/430',
    definition: `To dawdle or waste time. "Don't let him patchke, you'll be waiting around forever"`,
  },
  POLKEYS: {
    url: 'https://jel.jewish-languages.org/words/439',
    definition: `Thighs (esp. of a chicken or baby). "Next step is to crumb the polkeys"`,
  },
  SAKANAH: {
    url: 'https://jel.jewish-languages.org/words/469',
    definition: `Danger. "Don't stand too close to the edge, that cliff looks like a sakanah"`,
  },
  SCHMEAR: {
    url: 'https://jel.jewish-languages.org/words/478',
    definition: `To spread. "I need a bit of an extra schmear on my bagel, please"`,
  },
  SVIVA: {
    url: 'https://jel.jewish-languages.org/words/554',
    definition: `Environment, surroundings. "The svive at our local kibbutz are breathtaking"`,
  },
  TSORRIS: {
    url: 'https://jel.jewish-languages.org/words/585',
    definition: `Troubles, woes, worries, suffering. "Israel, and specifically the Jewish people, have experienced much tsorris over the years"`,
  },
  BEKISHE: {
    url: 'https://jel.jewish-languages.org/words/1324',
    definition: `Long black coat worn by Hasidic men. "Grandpa can't help with dinner, he's getting his bekishe ready"`,
  },
  MECHULA: {
    url: 'https://jel.jewish-languages.org/words/1356',
    definition: `Bankrupt, broke. "His business was doing so well, but then covid hit. Now he's mechula"`,
  },
  GESHEFT: {
    url: 'https://jel.jewish-languages.org/words/1357',
    definition: `Business. "Who knew social media would become such a lucrative gesheft"`,
  },
  BODEK: {
    url: 'https://jel.jewish-languages.org/words/1371',
    definition: `Check, specifically looking for insect that would render produce non-kosher. "Wash your strawberries,  make sure to bodek"`,
  },
  SIVUV: {
    url: 'https://jel.jewish-languages.org/words/1397',
    definition: `A go-around, walk-around, lap, especially one done in order to double-check something. "Bubbe always does a sivuv of the kids seder table to make sure they all got something to eat"`,
  },
  TORANUT: {
    url: 'https://jel.jewish-languages.org/words/1518',
    definition: `The task of serving and clearing food, and/or setting and clearing plates, at communal meals at Jewish summer camp. "The kids always dread toranut at summer camp"`,
  },
  PATUR: {
    url: 'https://jel.jewish-languages.org/words/1524',
    definition: `Not liable, example. "He's not patur for the decisions his father makes."`,
  },
  SVARA: {
    url: 'https://jel.jewish-languages.org/words/1564',
    definition: `Guess, hypothesis. "I can find countless svara to study Torah"`,
  },
  MASHPIA: {
    url: 'https://jel.jewish-languages.org/words/2146',
    definition: `With 'on') influential. "The way Rivky talks, it's been mashpia on me"`,
  },
  MITBACH: {
    url: 'https://jel.jewish-languages.org/words/1905',
    definition: `Kitchen. "When my bubbe is in the kitchen, I stay out of her way"`,
  },
  ARBES: {
    url: 'https://jel.jewish-languages.org/words/1972',
    definition: `Boiled chickpeas. "Without arbes, we would have no hummus and the world would be a sadder place"`,
  },
  EMESDIK: {
    url: 'https://jel.jewish-languages.org/words/2017',
    definition: `Truthful, genuine. "Ruti is emesdik, never says a unkind or untrue word"`,
  },
  AVEILUT: {
    url: 'https://jel.jewish-languages.org/words/2076',
    definition: `Mourning (Gilnert). "Sitting shiva is the traditional way to express aveilut.`,
  },
  BUSHA: {
    url: 'https://jel.jewish-languages.org/words/2091',
    definition: `Disgrace (Gilnert), shame, embarassment. "He had an affair and then ran away with the woman? What a busha!"`,
  },
  HIZUK: {
    url: 'https://jel.jewish-languages.org/words/2106',
    definition: `Encouragement (Gilnert). Inspiration, strengthening, uplifting. "Pay the kid a hizuk after he does the dishes or he'll never keep it up"`,
  },
  TAMEI: {
    url: 'https://jel.jewish-languages.org/words/2115',
    definition: `Ritually impure, unclean. "Never think less of people for being tamei"`,
  },
  SICHA: {
    url: 'https://jel.jewish-languages.org/words/2157',
    definition: `Public Talk. "In the sicha, the Rabbi was very expressive"`,
  },
  REMEZ: {
    url: 'https://jel.jewish-languages.org/words/2135',
    definition: `Interpretation/analysis of Torah on a symbolic level, taking it as an allegory, seeing what is implied. "The medrash was a remez to the need for teshuva"`,
  },
  SHENI: {
    url: 'https://jel.jewish-languages.org/words/2196',
    definition: `The second aliyah in a Torah service. "I'm leading sheni this Friday"`,
  },
  SHLISHI: {
    url: 'https://jel.jewish-languages.org/words/2197',
    definition: `The third aliyah in a Torah service. "My Bubba always fall alseep before shlishi"`,
  },
  MATIR: {
    url: 'https://jel.jewish-languages.org/words/2305',
    definition: `With 'to be') set free. "The Jews were to be matir from Egypt, but then Pharaoh changed his mind"`,
  },
  YESOD: {
    url: 'https://jel.jewish-languages.org/words/2306',
    definition: `Basis, or essential guiding principle: fundamental. "The yesod of my Bubbe is "if you're hungry, I will feed you. If you're full, I'll still feed you"`,
  },
  MEKOR: {
    url: 'https://jel.jewish-languages.org/words/2416',
    definition: `The source from which something is derived: origin. "The mekor for most of the bible is the Torah"`,
  },
  RABBA: {
    url: 'https://jel.jewish-languages.org/words/2501',
    definition: `Female rabbinic leader in the Modern Orthodox/Open Orthodox movement. "Did you hear that the synagogue down the street has a Rabba now?" `,
  },
  MAHARAT: {
    url: 'https://jel.jewish-languages.org/words/2502',
    definition: `Female rabbinic leader in the Modern Orthodox/Open Orthodox movement. "Temple Emanuel has a Maharat now- Esther Cohen" `,
  },
  BAITSIM: {
    url: 'https://jel.jewish-languages.org/words/37',
    definition: `Balls, testicles. "He has the baitsim to tell Rivky that her blintzes are awful"`,
  },
  GAYVE: {
    url: 'https://jel.jewish-languages.org/words/181',
    definition: `Pride, arrogance, obnoxiousness. "Gayve before the fall"`,
  },
  GOYTE: {
    url: 'https://jel.jewish-languages.org/words/204',
    definition: `Non-Jewish woman, especially domestic worker. "Have you sold our chametz to the goyte?"`,
  },
  CHAKIRA: {
    url: 'https://jel.jewish-languages.org/words/214',
    definition: `Proposition, treatise, deliberation. "She published a chakira on the benefits of hummus consumption"`,
  },
  KOVED: {
    url: 'https://jel.jewish-languages.org/words/282',
    definition: `Honour, dignity, respect. "The Rabbi carries himself with so much kavod, you forget how young he is"`,
  },
  LEARN: {
    url: 'https://jel.jewish-languages.org/words/303',
    definition: `To be involved in the acquisition of Torah knowledge through reading and reflection, or to do so as an occupation: STUDY. "Avi is going to learn with the Rebbe"`,
  },
  LIGHT: {
    url: 'https://jel.jewish-languages.org/words/310',
    definition: `Light Shabbat or holiday candles. "We need to light, it's getting dark"`,
  },
  LOSHN: {
    url: 'https://jel.jewish-languages.org/words/315',
    definition: `Language, jargon. "His explanation was so long, I don't remember the loshn. I'm paraphrasing"`,
  },
  MATZO: {
    url: 'https://jel.jewish-languages.org/words/345',
    definition: `Unleavened bread eaten on Passover. "We have 27 boxes of matzo, just enough for first night"`,
  },
  MEKAREV: {
    url: 'https://jel.jewish-languages.org/words/356',
    definition: `One who brings people closer to traditional Judaism. "He recruited six new people to the congregation, what a mekarev!"`,
  },
  MEKAYEM: {
    url: 'https://jel.jewish-languages.org/words/357',
    definition: `To fulfill an obligation. "All the pain on Yom Kippur is worth it to mekayam the mitzvah"`,
  },
  MELDADO: {
    url: 'https://jel.jewish-languages.org/words/360',
    definition: `Anniversary of someone's death. "I can't make it to dinner, it's the meldado of my Bubba today"`,
  },
  MEVATER: {
    url: 'https://jel.jewish-languages.org/words/369',
    definition: `To nulify one's desires for the good of others. "Dovi, try being mevater and let the other kids play with your toys"`,
  },
  MEZAKEH: {
    url: 'https://jel.jewish-languages.org/words/370',
    definition: `To acquire; strengthen. "It is a mitzvah to mezakeh the community with literature"`,
  },
  MOSHL: {
    url: 'https://jel.jewish-languages.org/words/388',
    definition: `Parable, example. "Waze is a moshl of Israeli's well-made tech"`,
  },
  ASIFA: {
    url: 'https://jel.jewish-languages.org/words/2185',
    definition: `Gathering. "I saw quite an asifa at the kotel today"`,
  },
  PEKEL: {
    url: 'https://jel.jewish-languages.org/words/432',
    definition: `Little bundle. "I made Dovi a pekel of snacks to take on camp"`,
  },
  PETEK: {
    url: 'https://jel.jewish-languages.org/words/433',
    definition: `Note. "We are maybe 5-10 years away from digital Kotel petek"`,
  },
  PLATA: {
    url: 'https://jel.jewish-languages.org/words/437',
    definition: `Electric hotplate used on the Sabbath. "Did you remember to turn on the plata?"`,
  },
  SHEFELE: {
    url: 'https://jel.jewish-languages.org/words/505',
    definition: `Sweetie. "Shefele, you can't eat that donut. It's Pesach"`,
  },
  SHMECKY: {
    url: 'https://jel.jewish-languages.org/words/524',
    definition: `Child-appropriate word for penis. "My Bubbe loves to tell stories of younger me running around with my shmecky flapping in the wind"`,
  },
  SHNOZ: {
    url: 'https://jel.jewish-languages.org/words/527',
    definition: `A nose, especially a large unattractive one. "My Zayde has a very distinctive schnoz"`,
  },
  TZETL: {
    url: 'https://jel.jewish-languages.org/words/2182',
    definition: `A flyer; written announcement. "My Bubbe went missing so we started writing up missing tzetel, turns out she was asleep in the bathtub"`,
  },
  YOSEM: {
    url: 'https://jel.jewish-languages.org/words/627',
    definition: `Orphan. "He's a yosum, it’s the 10 year anniversary of their passing"`,
  },
  NEDIVUT: {
    url: 'https://jel.jewish-languages.org/words/1282',
    definition: `Generosity. "Nedivut is one of the strongest traits amongst the Jewish people"`,
  },
  CHABURA: {
    url: 'https://jel.jewish-languages.org/words/1326',
    definition: `Study group. "I'm meeting with my chabura this evening to go over the next section of the Talmud"`,
  },
  ATZILUS: {
    url: 'https://jel.jewish-languages.org/words/2179',
    definition: `The highest spiritual universe in Lurianic Kabbalah. "Chassidim have said that the Rebbe had a neshama of atzilus"`,
  },
  SHTUM: {
    url: 'https://jel.jewish-languages.org/words/1354',
    definition: `Quiet, voiceless, mute. "Keep shtum, we don't want to wake Zayde"`,
  },
  SHISSEL: {
    url: 'https://jel.jewish-languages.org/words/1377',
    definition: `A large bowl, especially for mixing. "I don't have a big enough schissel to make that many knaidelech"`,
  },
  MALSHIN: {
    url: 'https://jel.jewish-languages.org/words/1398',
    definition: `To slander, malign, speak loshon hara about somebody. "The Jewish people have been the victim of malshin for decades"`,
  },
  PRITSUS: {
    url: 'https://jel.jewish-languages.org/words/1401',
    definition: `Immoral behaviour. "Ultra orthodox Jews are adverse to going to the movies on account of all the pristus on display"`,
  },
  GANTSEH: {
    url: 'https://jel.jewish-languages.org/words/1432',
    definition: `Entire, complete. "The gantesh congregation was in uproar at the mention of Haman's name"`,
  },
  BROIGES: {
    url: 'https://jel.jewish-languages.org/words/1469',
    definition: `Anrgy, annoyed. "The most important thing to do is keep Zayde from being broiges"`,
  },
  KITKE: {
    url: 'https://jel.jewish-languages.org/words/1475',
    definition: `Braided egg-bread eaten on the Sabbath (known as challah elsewhere). "Kitke is definitely not ok for coeliacs"`,
  },
  KUNTZ: {
    url: 'https://jel.jewish-languages.org/words/1509',
    definition: `Feat, stunt, trick, challenge, ingenuity. "Spinning a live chicken above your head on Yom Kippur? What a kuntz"`,
  },
  MABROUK: {
    url: 'https://jel.jewish-languages.org/words/1510',
    definition: `An expression of joyous congratulations used by Sephardi Jews equivalent to "Mazal Tov!". "You're son's started barmitzvah preparations? Mabrouk!"`,
  },
  OIRIK: {
    url: 'https://jel.jewish-languages.org/words/1545',
    definition: `Derogatory reference to a non-Jewish male, especially a common labourer. "I've been using the same oirik for years, he's a garden artist!"`,
  },
  JDATE: {
    url: 'https://jel.jewish-languages.org/words/1590',
    definition: `A popular dating site for Jewish singles. "I haven't been having any luck with tinder, my friend suggested JDate"`,
  },
  FARIBEL: {
    url: 'https://jel.jewish-languages.org/words/1623',
    definition: `Grievance. "My bubbe has a real faribel with hiphop music"`,
  },
  CHATICH: {
    url: 'https://jel.jewish-languages.org/words/1629',
    definition: `A sexy man. "Sampson was a real chatich, according to the legend"`,
  },
  SCHLONG: {
    url: 'https://jel.jewish-languages.org/words/1634',
    definition: `A penis. "His loud car is to compensate for his below-average schlong"`,
  },
  VANTZ: {
    url: 'https://jel.jewish-languages.org/words/1635',
    definition: `Bedbug. "Good night, sleep tight. Don't let the vantz bite"`,
  },
  SHVANTZ: {
    url: 'https://jel.jewish-languages.org/words/1636',
    definition: `Very rude man, lowlife. "My ex husband was a real shvantz"`,
  },
  SHEYMES: {
    url: 'https://jel.jewish-languages.org/words/1656',
    definition: `Of or relating to printed aterial, such as book or even audio recording, that contains the name of God, and therefore not to be erased, put on the floor, etc. "Careful with those books, Doovy! They have sheymos"`,
  },
  SHTIM: {
    url: 'https://jel.jewish-languages.org/words/1660',
    definition: `Often with preposition "with") to jibe, gel, concur, harominize; without prep. "You're saying a ghost ate the last cookie? I don't think that shtimms"`,
  },
  ALMEMAR: {
    url: 'https://jel.jewish-languages.org/words/1661',
    definition: `The platform on which the Torah is read in synagogue; the bima. "Will the batmizvah girl please make her way to the alemar"`,
  },
  BUJEW: {
    url: 'https://jel.jewish-languages.org/words/1673',
    definition: `A Jew who practices Buddhism. "Yakov just got back from spending 3 months with Monks in Tibet, he's a real Bujew"`,
  },
  MOSERIM: {
    url: 'https://jel.jewish-languages.org/words/1700',
    definition: `Informer. "The freedom fighters are on the lookout for moserim"`,
  },
  BERYA: {
    url: 'https://jel.jewish-languages.org/words/1731',
    definition: `Complimentary term for a talented, competent, energetic woman, especially one who gets a lot done - and does it well - around the house. "My Bubbe taught me to be the best berya"`,
  },
  KHREMZL: {
    url: 'https://jel.jewish-languages.org/words/1740',
    definition: `A fried fritter or latka made of matzah meal and eggs. "These khremzl remind me of the hash browns from our hotel"`,
  },
  ZIVUG: {
    url: 'https://jel.jewish-languages.org/words/1742',
    definition: `Mate, predestine match. "Rivky has yet to find her zivug, it's a wonder she's still so hopeful"`,
  },
  TAIVA: {
    url: 'https://jel.jewish-languages.org/words/1782',
    definition: `Lust, desire. "There's nothing more painful than being Jewish and having a tayve for bacon"`,
  },
  ADOSHEM: {
    url: 'https://jel.jewish-languages.org/words/1812',
    definition: `God. "Baruch Adoshem"`,
  },
  ASKAN: {
    url: 'https://jel.jewish-languages.org/words/1821',
    definition: `A Jewish communal or social worker. "He's a real askan, helping out the community wherever he can. What a mensch"`,
  },
  TZEIT: {
    url: 'https://jel.jewish-languages.org/words/1825',
    definition: `Nightfall; the time when three stars have appeared in the sky. "A baby boy born after tezit will have his circumcision exactly 8 days later"`,
  },
  SHAYACH: {
    url: 'https://jel.jewish-languages.org/words/1839',
    definition: `Pertintent, relevant. "Matzah Sushi? That's not sayach to me, I'm Sephardi"`,
  },
  HAFGANA: {
    url: 'https://jel.jewish-languages.org/words/1846',
    definition: `Demonstration, protest. "There are hafgana in all parts of Israel"`,
  },
  KASHE: {
    url: 'https://jel.jewish-languages.org/words/1851',
    definition: `A tough, perplexing question. "when are we getting grandkids? is the kashe I fear most from my parents"`,
  },
  PNIMI: {
    url: 'https://jel.jewish-languages.org/words/2166',
    definition: `A sincere Hasidic Jew. "Yossi is a famous chasid, a real pnimi"`,
  },
  MIGRASH: {
    url: 'https://jel.jewish-languages.org/words/1903',
    definition: `A sports court or field. "You. Us. 3-v-3 basketball. Meet  at the migrash after school"`,
  },
  TOREN: {
    url: 'https://jel.jewish-languages.org/words/1914',
    definition: `Flagpole. "The kids at our last camp all decided on the last day to run their undies up the toren"`,
  },
  MEGUYAR: {
    url: 'https://jel.jewish-languages.org/words/1918',
    definition: `A convert to Judaism. "His partner wasn't born Jewish, she's a meguyar"`,
  },
  DOIKAYT: {
    url: 'https://jel.jewish-languages.org/words/1926',
    definition: `Hereness. "However, the Folkists and the Jewish communists as well saw everything with 'doikayt' (here and now) i.e. in the daily life of the Jews in the Diaspora"`,
  },
  ADAFINA: {
    url: 'https://jel.jewish-languages.org/words/1933',
    definition: `A slowly cooked stew of meat and vegetables. "My Zayde has the best adafina, he makes it with coca cola"`,
  },
  ADMOR: {
    url: 'https://jel.jewish-languages.org/words/1936',
    definition: `A Hassidic spiritual leader. "Our neighours are Hassidic, the eldest son has started his preparations to take over as admor from his father"`,
  },
  CHUSH: {
    url: 'https://jel.jewish-languages.org/words/1939',
    definition: `Sense. "My little Dpvy has a sixth chush for when we have cookies in the house"`,
  },
  BENCHER: {
    url: 'https://jel.jewish-languages.org/words/1942',
    definition: `Prayer booklet or card with the text of the blessings after meals, and often times including other meal-time blessings and songs. "After lunch at school, we go to our classrooms and our teacher hands out benchers to recite Birkas HaMazon"`,
  },
  KIKAR: {
    url: 'https://jel.jewish-languages.org/words/1992',
    definition: `Square, open area. "I have a fear of pigeons because I was swarmed in the town kikar"`,
  },
  SCHAV: {
    url: 'https://jel.jewish-languages.org/words/2003',
    definition: `A cold cream-of-sorrel soup. "My bubbe loves making schav cause it means shabbos cooking is a breeze"`,
  },
  MEATY: {
    url: 'https://jel.jewish-languages.org/words/2009',
    definition: `Refers to food in the meat category according to kashrut. "My Zayde loves a good meaty lunch"`,
  },
  MILKY: {
    url: 'https://jel.jewish-languages.org/words/2010',
    definition: `Refers to dairy foods. "Is this a milky microwave?"`,
  },
  TOYVE: {
    url: 'https://jel.jewish-languages.org/words/2014',
    definition: `A favour. "Do me a toyve and get me my walker. Rivky has shopping to do!"`,
  },
  GENIZAH: {
    url: 'https://jel.jewish-languages.org/words/2024',
    definition: `A storage room in a synagogue where disused sacred books and often-sacred articles are kept; since bringing or disposing them otherwise is considered a sacrilege (these books and other items will ultimately buried in a Jewish cemetary). "Get the machzorim from the genizah"`,
  },
  KULAH: {
    url: 'https://jel.jewish-languages.org/words/2046',
    definition: `A lenient ruling or measure. "As we have seen, a kulah is a non-chumra"`,
  },
  ONESH: {
    url: 'https://jel.jewish-languages.org/words/2051',
    definition: `Punishment. "As onesh for using bad language, Rivky is making her kids listen to classical music"`,
  },
  TENAI: {
    url: 'https://jel.jewish-languages.org/words/2057',
    definition: `A condition, especially one made as part of an agreement. "Rivky has one too many tenai for cooking in her kitchen for my liking"`,
  },
  JAPPY: {
    url: 'https://jel.jewish-languages.org/words/2295',
    definition: `Pejorative) like a JAP. "So many of the girls are such Jewish Aussie Princesses. They could benefit from being less jappy"`,
  },
  NATLA: {
    url: 'https://jel.jewish-languages.org/words/3048',
    definition: `A ritual hand-washing vessel. "Lottie and Samuel got the most beautiful natla for their wedding"`,
  },
  HILLULA: {
    url: 'https://jel.jewish-languages.org/words/2314',
    definition: `The anniversary of the death of a great tzaddik; a celebration of the tzaddik's life on that day. "Public hillula celebrations take place on Lag ba-Omer, the traditional anniversary of the death of R. Simeon b. Yoḥai"`,
  },
  HEICHAL: {
    url: 'https://jel.jewish-languages.org/words/2342',
    definition: `The ark in a synagogue which holds the Sifrei Torah. "Before our hagba lesson, we'll get the Sifrei Torah out of the heichal"`,
  },
  BEITZAH: {
    url: 'https://jel.jewish-languages.org/words/2347',
    definition: `The roasted egg placed on the seder plate during the Pesach seder. "The beitzah is my favourite part of the Seder plate"`,
  },
  AKITZUR: {
    url: 'https://jel.jewish-languages.org/words/2409',
    definition: `In summary; in other words: restated. "Akitzur, she's jappy and I won't do business with her"`,
  },
  GMACH: {
    url: 'https://jel.jewish-languages.org/words/2435',
    definition: `A place where items are lent to people. "I was saved by the gemach down the street, I almost didn't have a suit for her wedding"`,
  },
};
