import { DEFINITIONS, WORDS } from '../constants/wordlist';
import { VALIDGUESSES } from '../constants/validGuesses';

export const isValidGuess = (word: string) => {
  return (
    solution.length &&
    ((WORDS as unknown as string[]).includes(word) ||
      VALIDGUESSES.includes(word.toLowerCase()))
  );
};

export const isWinningWord = (word: string) => {
  return solution === word;
};

export const getWordOfDay = () => {
  const epochMs = new Date('2 February, 2022 00:00:00').valueOf();
  const now = Date.now();
  const msInDay = 86400000;
  const index = Math.floor((now - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;
  const solution = WORDS[index % WORDS.length];

  return {
    solution,
    definition: DEFINITIONS[solution].definition,
    url: DEFINITIONS[solution].url,
    solutionIndex: index,
    tomorrow: nextday,
  };
};

export const { solution, definition, url, solutionIndex, tomorrow } =
  getWordOfDay();
