import classNames from 'classnames';
import { SHALOM_COLORS } from '../../constants/colors';

type Props = {
  index: number;
  size: number;
  label: string;
};

export const Progress = ({ index, size, label }: Props) => {
  const color = SHALOM_COLORS[index % SHALOM_COLORS.length];
  return (
    <div className="flex justify-left m-1">
      <div className="items-center justify-center w-2">{index + 1}</div>
      <div className="rounded-full w-full ml-2">
        <div
          style={{ width: `${5 + size}%` }}
          className={classNames(
            'text-xs font-medium text-gray-100 text-center p-0.5 rounded-l-full',
            {
              'bg-red-500': color === 'red',
              'bg-orange-500': color === 'orange',
              'bg-teal-500': color === 'teal',
              'bg-purple-500': color === 'purple',
            }
          )}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
