import { logEvent } from './analytics';
import { getGuessStatuses } from './statuses';
import { solutionIndex } from './words';

export const shareStatus = async (guesses: string[], lost: boolean) => {
  const text =
    `Jewdle ${solutionIndex + 1} ${lost ? 'X' : guesses.length}/6\n\n` +
    generateEmojiGrid(guesses) +
    '\n\nhttps://www.jewdle.app/';
  if (navigator.share) {
    navigator.share({ text });
    logEvent('result', 'share');
    return 'Successfully shared';
  } else {
    navigator.clipboard.writeText(text);
    logEvent('result', 'copy');
    return 'Game copied to clipboard';
  }
};

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess);
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩';
            case 'present':
              return '🟧';
            default:
              return '⬜';
          }
        })
        .join('');
    })
    .join('\n');
};
