import Countdown from 'react-countdown';
import { StatBar } from '../stats/StatBar';
import { Histogram } from '../stats/Histogram';
import { GameStats } from '../../lib/localStorage';
import { shareStatus } from '../../lib/share';
import { solution, definition, url, tomorrow } from '../../lib/words';
import { BaseModal } from './BaseModal';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  gameStats: GameStats;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShare: (alert: string) => void;
};

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal title="Statistics" isOpen={isOpen} handleClose={handleClose}>
        <StatBar gameStats={gameStats} />
      </BaseModal>
    );
  }
  return (
    <BaseModal title="Statistics" isOpen={isOpen} handleClose={handleClose}>
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        Guess Distribution
      </h4>
      <Histogram gameStats={gameStats} />
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6">
          <div className="rounded-lg p-2 bg-green-200">
            <p>
              <dfn>{solution}</dfn> – {definition}{' '}
              {url && (
                <a
                  className="underline text-purple-500 hover:text-purple-600"
                  href={url}
                >
                  Click here to learn more about this word.
                </a>
              )}
            </p>
          </div>
          <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
            <div>
              <h5>New word in</h5>
              <Countdown
                className="text-lg font-medium text-gray-900 dark:text-gray-100"
                date={tomorrow}
                daysInHours={true}
              />
            </div>
            <button
              type="button"
              className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm"
              onClick={async () => {
                const alert = await shareStatus(guesses, isGameLost);
                handleShare(alert);
              }}
            >
              Share
            </button>
          </div>
        </div>
      )}
    </BaseModal>
  );
};
